@import 'src/styles/palette.module';

.combining-card {
	.left-subtitle {
		text-transform: uppercase;
		font-weight: 555;
		line-height: 24px; /* 120% */
		letter-spacing: 0.4px;
	}

	.left-middle-text {
		font-family: Mohol;
		font-weight: 300;
	}
}

@media (width > 900px) {
	.combining-card.container {
		box-sizing: border-box;
		padding: 153px 133px 116px;
	}

	.combining-card {
		.left-subtitle {
			width: 60%;
			font-size: 20px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
		}

		.left-middle-text {
			margin-top: 19px;
			font-size: 48px;
			line-height: 64px;
			max-width: 890px;
		}

		.right-subtitle {
			margin: 139px 0 0 auto;
			max-width: 430px;
			font-size: 22px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.055px;
			& > b {
				font-weight: 650;
			}
		}
	}
}

@media (max-width: 900px) and (min-width: 700px) {
	.combining-card.container {
		margin: 100px 60px 100px;
	}

	.combining-card {
		.left-subtitle {
			font-size: 19px;
		}

		.left-middle-text {
			margin-top: 53px;
			font-size: 40px;
			line-height: 50px;
		}

		.right-subtitle {
			margin: 139px 0 0 auto;
			max-width: 490px;
			font-size: 22px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 1px;
		}
	}
}

@media (max-width: 700px) and (min-width: 400px) {
	.combining-card.container {
		margin: 85px 50px 90px;
	}

	.combining-card {
		.left-subtitle {
			width: auto;
			margin: 0 46px 0 0;
			font-size: 18px;
			line-height: 24px; /* 133.333% */
			letter-spacing: 0.36px;
		}

		.left-middle-text {
			margin-top: 21px;
			font-size: 35px;
			line-height: 45px;
		}

		.right-subtitle {
			margin: 75px 0 0 39px;
			max-width: 490px;
			font-size: 20px;
			font-weight: 500;
			line-height: 28px;
			letter-spacing: 1px;
		}
	}
}

@media (width < 700px) {
	.combining-card.container {
		margin: 77px 24px 88px;
	}

	.left-subtitle {
		width: auto;
		margin: 0 46px 0 0;
		font-size: 18px;
		line-height: 24px; /* 133.333% */
		letter-spacing: 0.36px;
	}

	.left-middle-text {
		margin-top: 46px;
		font-size: 26px !important;
		font-weight: 300;
		line-height: 32px !important; /* 123.077% */
		letter-spacing: -0.13px;
	}

	.right-subtitle {
		margin: 75px 0 0 39px;
		font-size: 19px;
		font-weight: 500;
		line-height: 24px; /* 126.316% */
		letter-spacing: 1px;
	}
}
