@import 'src/styles/palette.module';

.how-it-works {
	& .button-dark.contact-button {
		color: $off-white;
	}
	& .menu-button.closed {
		rect {
			fill: $off-white;
			&:first-child {
				fill: $dark-blue;
			}
		}
	}
	& .changed-logo {
		svg.circle {
			& > path {
				fill: $off-white;
			}
		}
	}
	.title-card {
		margin: 0 133px;
		& > .container {
			background: $off-white;
			height: initial;
		}
		.title-card-title.card-title {
			font-family: Mohol;
			font-size: 72px;
			font-weight: 300;
			line-height: 80px;
			letter-spacing: -0.01em;
			text-align: center;
			color: $dark-blue;
			margin: 0 auto 38px;
		}
		.title-card-subtitle {
			font-family: Degular;
			font-size: 26px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.0025em;
			text-align: center;
			color: $dark-blue;
			max-width: 718px;
			width: 100%;
			margin-bottom: 75px;
		}
	}
	@media (width < 1000px) {
		.title-card {
			margin: 0 10%;
		}
	}
	@media (width < 700px) {
		.title-card {
			margin: 0 24px;
			.title-card-title.card-title {
				font-size: 40px;
				line-height: 40px;
				margin: 0 auto 30px;
				padding-right: 0;
			}
			.title-card-subtitle {
				font-size: 22px;
				line-height: 32px;
				margin-bottom: 18px;
			}
		}
	}
}
