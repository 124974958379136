@import 'src/styles/palette.module';

.privacy-enhancing-technologies {
	.how-pets-work {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.how-pets-work-content {
			padding: 170px 133px 199px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			& > div:first-child {
				margin-right: 50px;
				box-sizing: border-box;
			}
		}
		.how-pets-work-title {
			font-family: Mohol;
			font-size: 72px;
			font-weight: 300;
			line-height: 80px;
			letter-spacing: -0.01em;
			text-align: left;
			color: $dark-blue;
		}
		.how-pets-work-text {
			margin: 37px 0 0 114px;
			font-family: Degular;
			font-size: 22px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.0025em;
			text-align: left;
			color: $dark-blue;
			max-width: 490px;
			width: 100%;
			& b {
				font-weight: 650;
			}
			& > div:first-child {
				margin-bottom: 20px;
			}
		}
		.how-pets-work-img {
			max-width: 420px;
			& > img {
				max-width: inherit;
				margin: 0;
			}
		}
		.how-pets-work-quote {
			box-sizing: border-box;
			align-self: flex-end;
			background: $turqoise;
			padding: 92px 133px 81px 114px;
			border-radius: 50px 0 0 50px;
			color: $dark-blue;
			max-width: 965px;
			width: calc(100% - 114px);
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
			@media (width > 1800px) {
				padding: 92px calc((100vw - 1440px) / 2 + 133px) 81px 114px;
				max-width: 60vw;
			}
			.how-pets-work-quote-title {
				font-family: Mohol;
				font-size: 48px;
				font-weight: 300;
				line-height: 64px;
				text-align: left;
				margin-bottom: 67px;
				position: relative;
				&::before {
					content: url('../../resources/img/quotes.svg');
					width: 30px;
					height: 30px;
					position: absolute;
					top: -30px;
					left: -40px;
				}
				& > span {
					font-family: Degular; //need to be Mohol
				}
			}
			.how-pets-work-quote-text {
				font-family: Degular;
				font-size: 16px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				text-transform: uppercase;
			}
		}
	}
	@media (width < 1300px) {
		.how-pets-work {
			.how-pets-work-text {
				margin-left: 50px;
			}
		}
	}
	@media (width < 1000px) {
		.how-pets-work {
			.how-pets-work-content {
				padding: 170px 10% 199px;
			}
			.how-pets-work-img {
				max-width: 300px;
				& > img {
					max-width: inherit;
					margin: 0;
				}
			}
			.how-pets-work-quote {
				width: 90%;
			}
		}
	}
	@media (width < 700px) {
		.how-pets-work {
			margin-bottom: 96px;
			.how-pets-work-content {
				padding: 85px 24px 80px;
				flex-direction: column;
				& > div:first-child {
					margin-right: 0;
				}
			}
			.how-pets-work-img {
				max-width: 327px;
			}
			.how-pets-work-quote {
				width: calc(100% - 24px);
			}
			.how-pets-work-title {
				font-size: 36px;
				line-height: 40px;
			}
			.how-pets-work-text {
				font-size: 19px;
				line-height: 24px;
				padding: 26px 0 49px 39px;
				margin: 0;
				box-sizing: border-box;
			}
			.how-pets-work-quote {
				padding: 45px 24px 45px 60px;
				border-radius: 50px 0 0 50px;
				width: calc(100% - 24px);
				.how-pets-work-quote-title {
					font-size: 26px;
					line-height: 32px;
					margin-bottom: 50px;
					&::before {
						top: -15px;
						left: -40px;
					}
				}
			}
		}
	}
}
