@import 'src/styles/palette.module';

.about-us {
	& > .title-card {
		& > .container {
			height: initial;
		}
	}
	margin-bottom: -50px;
	.flex-center {
		align-items: center;
	}
	.flex-center.flex-column {
		margin: 0 133px 70px;
	}
	.title-card.background {
		border-radius: 0;
	}
	.title-card-title {
		margin-left: 0;
		max-width: 100%;
		font-size: 72px;
		font-weight: 300;
		line-height: 80px;
		letter-spacing: -0.01em;
		text-align: center;
	}
	@media (width < 1000px) {
		.flex-center.flex-column {
			margin: 0 10%;
		}
	}
	@media (width < 700px) {
		.flex-center.flex-column {
			margin: 0 24px 60px;
		}
		.title-card-title {
			font-size: 36px;
			line-height: 40px;
		}
		.title-card {
			.title-arrow {
				margin-bottom: 0;
			}
			.container {
				padding-bottom: 20px;
			}
		}
	}
}
