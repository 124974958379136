@import 'src/styles/palette.module';

.features-card {
	.container {
		padding: 226px 247px 0 133px;
		box-sizing: border-box;
	}

	.card-title {
		font-family: Mohol;
		font-size: 54px;
		font-style: normal;
		font-weight: 300;
	}

	.list {
		margin: 48px 0 0 342px;
	}

	.list-item-with-arrow {
		border-bottom: 1px solid $dark-blue;
	}

	@media (width < 1200px) {
		.container {
			padding: 170px 100px 0;
		}

		.card-title {
			font-size: 40px;
		}

		.list {
			margin: 25px 0 0 200px;
		}
	}

	@media (width < 700px) {
		.container {
			padding: 121px 24px 0;
		}

		.card-title {
			font-size: 32px;
			margin-bottom: 35px;
		}

		.list {
			margin: 30px 0 0 39px;
		}
	}
}
