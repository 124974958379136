@import 'src/styles/palette.module';

.secure-data-card .container {
	padding: 147px 133px 180px;
	display: flex;
	flex-direction: column;
}

.secure-data-card .privacy-card-container {
	display: flex;
	margin: 0 34px;
	box-sizing: border-box;
	.privacy-card {
		padding: 25px 25px 37px 43px;
		border-radius: 50px;
		background: $dark-blue;
		border: 2px solid $off-white;
		display: flex;
		flex-direction: column;
		width: 34%;
		box-sizing: border-box;
		& > span {
			font-family: Degular;
			font-size: 30px;
			font-weight: 555;
			line-height: 32px;
			letter-spacing: 0.02em;
			text-align: left;
			color: $off-white;
			text-transform: uppercase;
		}
		&:nth-child(1) {
			margin-top: 176px;
			margin-right: -114px;
			justify-content: space-between;
			align-items: flex-end;
			z-index: 4;
			height: 288px;
			& > span {
				align-self: flex-start;
			}
		}
		&:nth-child(2) {
			justify-content: flex-start;
			z-index: 3;
			align-items: flex-end;
			height: 376px;
			& > span {
				margin-top: 36px;
			}
		}
		&:nth-child(3) {
			justify-content: space-between;
			align-items: flex-end;
			margin-top: 240px;
			margin-left: -79px;
			z-index: 2;
			height: 344px;
			& > span {
				align-self: flex-start;
			}
		}
		&:nth-child(4) {
			justify-content: flex-start;
			align-items: flex-end;
			margin-top: 120px;
			margin-left: -69px;
			z-index: 1;
			height: 304px;
			& > span {
				margin-top: 39px;
				max-width: 147px;
				text-align: right;
			}
		}
	}
	@media (width < 1200px) {
		flex-direction: column;
		align-items: center;
		.privacy-card {
			width: 65%;
			max-width: 420px;
			&:nth-child(1) {
				margin-top: 66px;
				margin-right: 117px;
			}
			&:nth-child(2) {
				margin-right: -300px;
				margin-top: -120px;
			}
			&:nth-child(3) {
				margin-right: -150px;
				margin-top: -120px;
				z-index: 3;
			}
			&:nth-child(4) {
				margin-top: -45px;
				z-index: 2;
				margin-left: 300px;
			}
		}
	}

	@media (width < 700px) {
		margin: 0 24px;
		align-items: flex-start;
		.privacy-card {
			border-radius: 30px;
			padding: 15px;
			& > span {
				font-size: 20px;
				line-height: 24px;
			}
			& > svg {
				width: 40px;
				height: 40px;
			}
			&:nth-child(1) {
				margin-top: 66px;
				margin-right: 0;
				margin-left: 39px;
				width: 171px;
				height: 144px;
			}
			&:nth-child(2) {
				margin-right: 0;
				margin-left: 160px;
				margin-top: -80px;
				width: 171px;
				height: 184px;
				& > span {
					margin-top: 14px;
				}
			}
			&:nth-child(3) {
				margin-right: 0;
				margin-top: -60px;
				z-index: 3;
				width: 210px;
				height: 168px;
				margin-left: 0;
			}
			&:nth-child(4) {
				margin-top: -45px;
				z-index: 2;
				margin-left: 130px;
				width: 171px;
				height: 152px;
			}
		}
	}
}
.secure-data-img {
	height: fit-content;
	width: 43%;
	padding-top: 10px;
	margin: auto;
}

.secure-data-img-mobile {
	display: none;
}

.secure-data-card .card-title {
	font-size: 72px;
	line-height: 80px;
}

.secure-data-card .card-subtitle {
	margin-left: 114px;
	margin-top: 37px;
	line-height: 32px;
}

.secure-data-first-row {
	gap: 80px;
}

.privacy-card-vertical {
	display: none;
}

.privacy-text {
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 48px;
	font-style: normal;
	font-weight: 300;
	line-height: 64px;
	width: 85%;
	margin-top: 145px;
	& > a {
		border-bottom: 2px solid $dark-blue;
		color: $dark-blue;
	}
}

.privacy-subtext {
	margin-left: auto;
	width: 43%;
	font-family: Degular;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
	margin-top: 205px;
	letter-spacing: 0.7px;
}

.privacy-card-horizontal {
	width: 96%;
	margin: 0 2%;
}

@media (width < 1000px) {
	.secure-data-card .container {
		padding: 10%;
		display: flex;
		flex-direction: column;
	}

	.secure-data-first-row {
		flex-direction: column;
	}

	.secure-data-img {
		display: none;
	}

	.secure-data-img-mobile {
		display: block;
		padding: 10%;
	}

	.secure-data-card .card-subtitle {
		margin-left: 0;
	}

	.privacy-card-horizontal {
		display: block;
	}

	.privacy-card-vertical {
		display: none;
		margin-top: 72px;
	}

	.privacy-text {
		font-size: 35px;
		line-height: 50px;
		margin-top: 50px;
	}

	.privacy-subtext {
		margin-top: 80px;
		width: 60%;
		margin-bottom: 20px;
	}
}

@media (width < 700px) {
	.secure-data-img-mobile {
		display: block;
		padding: 5%;
	}

	.secure-data-card .card-subtitle {
		margin-top: 47px;
	}

	.secure-data-card .card-title {
		font-size: 45px;
		line-height: 50px;
		margin: 0;
		padding: 0;
	}

	.privacy-text {
		font-size: 26px;
		line-height: 32px;
		width: 100%;
		margin-top: 85px;
	}

	.privacy-subtext {
		font-size: 19px;
		font-weight: 500;
		letter-spacing: 0.52px;
		line-height: 24px;
		width: 83%;
		margin-top: 85px;
		margin-left: auto;
	}

	.privacy-card-vertical {
		display: block;
	}

	.privacy-card-horizontal {
		display: none;
	}

	.privacy-card-vertical {
		margin: 5%;
	}
}

@media (width < 400px) {
	.secure-data-card .container {
		flex-direction: column;
		padding: 0;
		margin: 0 0 80px 0;
	}

	.secure-data-card .card-title {
		font-size: 36px;
		line-height: 40px;
		margin: 0px;
		letter-spacing: 0.1px;
		padding: 61px 24px 0;
	}

	.secure-data-card .card-subtitle {
		margin: 25px 0 0 39px;
		font-size: 19px;
		line-height: 24px;
		letter-spacing: 0.7px;
		padding: 0 28px 0 24px;
	}

	.secure-data-img {
		height: fit-content;
		display: none;
	}

	.secure-data-img-mobile {
		display: block;
		padding: 4px;
	}

	.privacy-text {
		margin-top: 20px;
		padding: 0 28px 0 24px;
		width: auto;
		font-weight: 500;
	}

	.privacy-subtext {
		margin: 81px 24px 0 63px;
		font-weight: 550;
		width: auto;
	}

	.privacy-card-vertical {
		margin: 50px 6% 14px 6%;
	}

	.privacy-card-vertical {
		margin-left: 24px;
		margin-right: 24px;
		margin-top: 60px;
	}

	.collaboration-card .laptop-img {
		display: none;
	}
}
