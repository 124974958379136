.how-it-works {
	.how-it-works-rest {
		padding: 280px 0 174px;
	}
	@media (width < 700px) {
		.how-it-works-rest {
			padding: 120px 0 80px;
		}
	}
}
