@import 'src/styles/palette.module';

.privacy-enhancing-technologies {
	.privacy-enhancing-technologies-what-block {
		padding: 126px 133px 183px;
	}
	.privacy-enhancing-technologies-question {
		font-size: 20px;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.02em;
		text-align: left;
		color: $dark-blue;
		margin-bottom: 27px;
		text-transform: uppercase;
	}
	.privacy-enhancing-technologies-answer {
		font-family: Mohol;
		font-size: 48px;
		font-weight: 300;
		line-height: 64px;
		text-align: left;
		color: $dark-blue;
		max-width: 912px;
		width: 100%;
		& > span {
			border-bottom: 2px solid $dark-blue;
		}
	}
	@media (width < 1000px) {
		.privacy-enhancing-technologies-what-block {
			padding: 100px 10% 100px;
		}
	}
	@media (width < 700px) {
		.privacy-enhancing-technologies-what-block {
			padding: 66px 24px 116px;
			.privacy-enhancing-technologies-question {
				font-size: 18px;
			}
			.privacy-enhancing-technologies-answer {
				font-size: 26px;
				line-height: 32px;
			}
		}
	}
}
