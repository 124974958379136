@import 'src/styles/palette.module';

.demo-route .light-background {
	padding: 0 167px 155px;

	.img-wrapper {
		display: flex;
		justify-content: center;
	}

	img {
		margin: -200px auto 90px;
		width: 100%;
		max-width: 1106px;
	}

	.commited {
		font-feature-settings:
			'ss04' on,
			'ss03' on,
			'salt' on;
		font-family: Mohol;
		font-size: 48px;
		font-style: normal;
		font-weight: 300;
		line-height: 64px;
		width: 80%;
		& > a {
			color: $dark-blue;
			border-bottom: 2px solid $dark-blue;
		}
	}

	.try-it-now {
		background-color: $off-white;
		margin-bottom: 0px;
	}

	@media (max-width: 1200px) {
		padding: 0 100px 155px;

		.img {
			margin: -200px auto 90px auto;
		}

		.commited {
			font-size: 36px;
			line-height: 48px;
			width: 80%;
		}
	}

	@media (max-width: 800px) {
		padding: 0 50px 155px;
		.img {
			margin: -250px auto 90px auto;
		}
	}

	@media (max-width: 600px) {
		padding: 0 0 155px 50px;

		.commited {
			font-size: 32px;
			line-height: 40px;
			width: 100%;
			padding-right: 50px;
			box-sizing: border-box;
		}

		.img-wrapper {
			display: flex;
			justify-content: flex-start;
			overflow-x: clip;
		}

		.img {
			height: 504px;
			margin: -240px auto 63px auto;
			width: auto;
		}
	}

	@media (max-width: 500px) {
		padding: 0 0 60px 0;

		.commited {
			font-size: 26px;
			line-height: 40px;
			line-height: 32px; /* 123.077% */
			letter-spacing: -0.13px;
			box-sizing: border-box;
			margin-left: 24px;
			margin-right: 24px;
			width: calc(100% - 24px * 2);
			padding: 0;
		}

		.img {
			margin: -385px auto 63px auto;
			height: 360px;
		}
	}
}
