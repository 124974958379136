@import 'src/styles/palette.module';

.home-page-route {
	overflow-x: hidden;
	.title-card .title-card-title a {
		margin-left: 30px;
	}

	.title-card .title-card-title svg,
	.our-platform-button svg {
		margin-bottom: -5px;
	}

	.title-card a {
		text-decoration: none;
	}

	.our-platform-button.display-bigscreen {
		margin-left: 5px;
		font-weight: 500;
	}

	.title-card > .container {
		background: url('../../resources/img/titleCard.svg') no-repeat;
		background-position: 92% 68%;
		background-size: 34%;
		width: 100%;
		border-radius: 0px 0px 50px 50px;
		color: $dark-blue;
		padding-right: 0;
	}

	.title-card .card-subtitle {
		padding-bottom: 73px;
	}

	.title-card .title-arrow {
		animation: animate 2s infinite;
		animation-delay: 1s;
		transition: 0.5s ease;
		position: relative;
	}

	@keyframes animate {
		from {
			opacity: 0;
			top: -20px;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			top: 0;
		}
	}

	@media (width > 1299px) {
		.our-platform-button {
			margin-left: 40px;
			position: relative;
			top: -12px;
			font-size: 20px;
			letter-spacing: 2px;
			cursor: pointer;
			font-size: 24px;
		}
	}

	@media (width < 1300px) {
		.title-card > .container {
			background-image: url('../../resources/img/titleCardMB.svg');
			background-position: 85% 60%;
			background-size: 45%;
		}

		.title-card .card-subtitle {
			font-size: 19px;
			margin-top: 300px;
			margin-left: 70px;
			margin-top: 250px;
			padding-right: 70px;
		}

		.title-card-subtitle,
		.title-card-title {
			margin-left: 70px;
			margin-right: 70px;
		}
	}

	@media (width < 1300px) and (width > 699px) {
		.title-card .title-card-title a {
			display: block;
			margin-left: 0;
			margin-top: 20px;
		}
	}

	@media (width < 700px) {
		.title-card > .container {
			border-radius: 0px 0px 40px 40px;
			background-position: 25% 48%;
			background-size: 70%;
			padding: 115px 0 48px;
		}

		.title-card .card-subtitle {
			margin-top: 70% !important;
			margin-top: 300px;
			padding-bottom: 26px;
			margin-left: 24px;
			padding-right: 24px;
		}

		.title-card-title {
			width: 90%;
			padding-right: 0 !important;
			letter-spacing: -0.2px;
			width: auto;
		}
		.title-card-subtitle,
		.title-card-title {
			margin-left: clamp(24px, 6%, 50px);
			margin-right: clamp(24px, 6%, 50px);
		}
		button.display-smallscreen {
			padding: 20px 50px;
			font-size: 20px;
		}
	}

	@media (width < 400px) {
		.title-card > .container {
			border-radius: 0px 0px 40px 40px;
			background-position: 44% 43%;
			background-size: 88%;
		}
		.title-card .card-subtitle {
			letter-spacing: 0.9px;
			margin-left: 24px;
			padding-right: 24px;
		}
	}

	@media (width < 375px) {
		.title-card > .container {
			background-position: 25% 40%;
		}

		.title-card .card-subtitle {
			margin-top: 10em !important;
		}
	}
}
