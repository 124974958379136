@import 'src/styles/palette.module';

.partners-title {
	font-size: 20px;
	font-style: normal;
	font-weight: 555;
	line-height: 24px;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	padding: 0 133px 74px;
}

.partners img {
	margin-right: 79px;
}

.logo-scroller {
	display: flex;
	overflow: hidden;
	margin: 0 0 120px;
}

.logo {
	animation: moveLogo linear infinite;
	animation-duration: 20s;
	animation-timing-function: linear;
}

.logo img {
	width: 1140px;
	height: auto;
}

@keyframes moveLogo {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

@media (width < 1200px) {
	.logo-scroller {
		margin: 0 0 87px;
	}
}

@media (width < 700px) {
	.partners {
		padding: 77px 24px 84px;
		width: intrinsic;
	}

	.partners-title {
		font-size: 18px;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin: 77px auto 30px 24px;
		padding: 0 !important;
		font-style: normal;
	}

	.partners-title {
		padding: 0 100px 45px;
	}

	.partners img {
		margin-right: 30px;
		height: 35px;
	}

	.logo-scroller {
		margin: 0 0 63px;
	}

	.logo img {
		width: 750px;
		height: auto;
	}
}
