@import 'src/styles/palette.module';

.privacy-enhancing-technologies {
	.privacy-enhancing-technologies-why-block {
		padding: 94px 133px 112px;
		.privacy-enhancing-technologies-question {
			font-size: 20px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
			color: $off-white;
			margin-bottom: 27px;
			text-transform: uppercase;
		}
	}
	@media (width < 1000px) {
		.privacy-enhancing-technologies-why-block {
			padding: 94px 10% 112px;
		}
	}
	@media (width < 700px) {
		.privacy-enhancing-technologies-why-block {
			padding: 55px 39px 80px 0;
			.privacy-enhancing-technologies-question {
				font-size: 18px;
				margin-left: 24px;
			}
		}
	}
}

@media (width < 700px) {
	.privacy-enhancing-technologies-why-container {
		margin-right: 24px;
	}
}
