@import 'src/styles/palette.module';

.resources {
	.light-background {
		margin-top: -140px;
		border-radius: 50px 50px 0 0;
	}
	.content {
		margin: 78px 133px 152px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.filters-container {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			margin: 80px 0;
			.filters {
				display: flex;
				flex-direction: column;
				margin-left: 20px;
				button {
					background: transparent;
					border: none;
					font-family: Degular;
					font-size: 20px;
					font-weight: 555;
					line-height: 24px;
					letter-spacing: 0.02em;
					text-align: left;
					color: $dark-blue;
					margin-top: 28px;
					border-bottom: 1.5px solid $dark-blue;
					text-transform: uppercase;
					align-self: flex-end;
				}
				.filters-label {
					color: $dark-blue;
					font-family: Degular;
					font-size: 16px;
					font-weight: 555;
					line-height: 18px;
					letter-spacing: 0.02em;
					margin-top: -30px;
					text-transform: uppercase;
				}
				.filters-items {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-top: 10px;
					flex-wrap: wrap;
					gap: 8px;
					.filters-item {
						padding: 21px 24px;
						border-radius: 100px;
						border: 2px solid $dark-blue;
						background: transparent;
						color: $dark-blue;
						font-family: Degular;
						font-size: 20px;
						font-weight: 555;
						line-height: 24px;
						letter-spacing: 0.02em;
						text-align: left;
						text-transform: uppercase;
						transition: all 0.3s ease-in-out;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						& > span.icon {
							display: none;
						}
						&.active {
							background: $dark-blue;
							padding: 17px 24px;
							color: $off-white;
							position: relative;
							& > span.icon {
								display: inline-block;
								color: $off-white;
								font-family: Degular;
								font-size: 26px;
								font-weight: 500;
								line-height: 24px;
								letter-spacing: 0.0025em;
								text-align: left;
								padding-left: 8px;
							}
						}
					}
				}
			}
		}
		.content-items-container {
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
			& > .you-might-be-interested-item {
				width: calc(100% / 3 - 27px);
			}
		}

		& > .content-show-more {
			padding: 20px 68px;
			background: transparent;
			color: $dark-blue;
			border: 2px solid $dark-blue;
			margin-top: 90px;
			border-radius: 50px;
			font-family: Degular;
			font-size: 24px;
			font-weight: 555;
			line-height: 32px;
			letter-spacing: 0.02em;
			text-align: center;
			color: $dark-blue;
		}
		@media (width < 1200px) {
			margin: 78px 10% 152px;
			.content-items-container {
				gap: 20px;
				justify-content: center;
				& > .you-might-be-interested-item {
					width: calc(100% / 2 - 10px);
				}
			}
		}
		@media (width < 1100px) {
			margin: 114px 24px 88px;
			.content-items-container {
				gap: 16px;
				& > .you-might-be-interested-item {
					width: 100%;
					max-width: 400px;
				}
			}
			.filters-container {
				flex-direction: column;
				.MuiFormControl-root.MuiTextField-root {
					width: 100%;
					max-width: 500px;
					button {
						padding: 15px 20px;
						border-radius: 100px;
						span {
							display: none;
						}
						svg {
							display: block;
						}
					}
				}
				.filters {
					margin-left: 0;
					margin-top: 60px;
					width: 100%;
					.filters-label {
						margin-left: 15px;
					}
					.filters-items {
						max-width: 600px;
						.filters-item {
							width: calc(50% - 4px - 4px);
							font-size: 18px;
							line-height: 24px;
							padding: 20px 0;
							display: flex;
							justify-content: center;
							align-items: center;
							max-width: 300px;
							&.active {
								&::after {
									top: 16px;
									right: 30px;
								}
							}
						}
					}
					button {
						font-size: 18px;
						line-height: 24px;
						margin-top: 47px;
					}
				}
			}
			.content-show-more {
				margin-top: 64px;
				padding: 20px 40px;
				font-size: 20px;
				line-height: 24px;
			}
		}
	}
	.protect-data {
		margin-bottom: 260px;
		@media (width < 900px) {
			margin-bottom: 100px;
		}
	}
	@media (width < 900px) {
		.light-background {
			margin-top: -145px;
			border-radius: 40px 40px 0 0;
		}
	}

	@media (width < 500px) {
		.filters-container {
			.filters {
				.filters-items {
					.filters-item {
						&.active {
							&::after {
								right: 10px !important;
							}
						}
					}
				}
			}
		}
	}
}
