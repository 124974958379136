@import 'src/styles/palette.module';

.you-might-be-interested {
	margin: 0 133px;
}
.you-might-be-interested-title {
	font-family: Mohol;
	font-size: 54px;
	font-weight: 300;
	line-height: 64px;
	letter-spacing: -0.005em;
	text-align: center;
	color: $dark-blue;
	margin: 0 auto 72px;
	font-feature-settings:
		'dlig' on,
		'salt' on,
		'ss03' on,
		'ss04' on;
}
.you-might-be-interested-items {
	gap: 1em;
	display: flex;
	flex-wrap: no-wrap;
	overflow-x: auto;
	padding-bottom: 80px;
	scrollbar-color: $turqoise $dark-blue;
	scrollbar-width: thin;
}
.you-might-be-interested-item {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	width: calc(100% / 3 - 0.5em);
	height: 100%;
	box-shadow: 0px 15px 20px 0px #00c4951a;
	background: white;
	border-radius: 20px;
	overflow: hidden;

	.you-might-be-interested-item-picture {
		width: 100%;
		border-radius: 20px;
		height: calc(100% / 3);
		border-radius: 20px;
		overflow: hidden;
		& > img {
			display: block;
			width: 100%;
			height: 100%;
			max-height: 242px;
			object-fit: contain;
			aspect-ratio: 291 / 194;
		}
	}

	.you-might-be-interested-item-header {
		display: flex;
		justify-content: space-between;
		margin: 17px 32px 20px;

		.you-might-be-interested-item-label {
			font-family: Degular;
			font-size: 16px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
			padding: 3px 12px;
			border-radius: 100px;
			margin-top: 4px;
		}
		.you-might-be-interested-item-date {
			color: rgba(15, 25, 54, 0.5);
			font-family: Degular;
			font-size: 16px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: right;
		}
	}
	.you-might-be-interested-item-title {
		margin: 0 32px 15px;
		color: $dark-blue;
		font-family: Mohol;
		font-size: 32px;
		font-weight: 300;
		line-height: 36px;
		letter-spacing: 0.0025em;
		text-align: left;
		font-feature-settings:
			'dlig' on,
			'salt' on,
			'ss03' on,
			'ss04' on;
	}
	.you-might-be-interested-item-description {
		margin: 0 32px 0;
		color: $dark-blue;
		font-family: Degular;
		font-size: 20px;
		font-weight: 529;
		line-height: 26px;
		letter-spacing: 0.075em;
		text-align: left;
		overflow: hidden;
		display: -webkit-box;
		line-clamp: 5;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		box-sizing: border-box;
		height: 100%;
	}
	.you-might-be-interested-item-link {
		align-self: flex-end;
		color: $dark-blue;
		font-family: Degular;
		font-size: 20px;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.02em;
		text-align: right;
		display: flex;
		margin: 41px 32px 43px;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			height: 2px;
			background: $dark-blue;
			position: absolute;
			bottom: -4px;
			left: 0;
			right: 0;
		}
		& > .title-arrow {
			margin-left: 10px;
		}
	}
}

@media (width < 1000px) {
	.you-might-be-interested {
		margin: 0 10%;
	}
	.you-might-be-interested-item {
		width: calc(100% / 2 - 0.5em);
	}
}
@media (width < 700px) {
	.you-might-be-interested {
		margin: 0 0 0 24px;
	}

	.you-might-be-interested-title {
		text-align: left;
		font-size: 32px;
		line-height: 36px;
		margin-bottom: 40px;
	}
	.you-might-be-interested-items {
		gap: 24px;
	}
	.you-might-be-interested-item {
		width: calc(100% - 24px - 50px);
		.you-might-be-interested-item-header {
			align-items: center;
			margin: 10px 20px 20px;
		}
		.you-might-be-interested-item-title {
			font-size: 22px;
			line-height: 24px;
			margin: 0 20px 20px;
		}
		.you-might-be-interested-item-description {
			margin: 0 20px 28px;
			font-size: 17px;
			line-height: 22px;
		}
		.you-might-be-interested-item-link {
			font-size: 18px;
			line-height: 24px;
			margin: 0 20px 20px 20px;
		}
	}
}
