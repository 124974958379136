@import 'src/styles/palette.module';

.privacy-enhancing-technologies {
	.useful-resources {
		margin: 169px 133px 144px;
		.useful-resources-title {
			font-family: Mohol;
			font-size: 54px;
			font-weight: 300;
			line-height: 64px;
			letter-spacing: -0.005em;
			text-align: center;
			color: $dark-blue;
			margin: 0 auto 63px auto;
		}
		.useful-resources-items {
			display: flex;
			justify-content: space-between;
			gap: 2em;
			.useful-resources-item {
				max-width: 364px;
				background: $turqoise;
				border-radius: 20px;
				padding: 20px 30px;
				min-height: 256px;
				width: calc(100% / 3);
				box-sizing: border-box;
				.useful-resources-item-header {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					margin-bottom: 16px;
					.useful-resources-item-label {
						font-family: Degular;
						font-size: 16px;
						font-weight: 555;
						line-height: 24px;
						letter-spacing: 0.02em;
						text-align: left;
						text-transform: uppercase;
						color: $dark-blue;
						padding: 3px 12px;
						border-radius: 100px;
						background: $off-white;
					}
					.useful-resources-item-date {
						text-transform: uppercase;
						font-family: Degular;
						font-size: 16px;
						font-weight: 555;
						line-height: 24px;
						letter-spacing: 0.02em;
						text-align: right;
						color: $dark-blue;
					}
				}
				.useful-resources-item-title {
					color: $dark-blue;
					font-family: Degular;
					font-size: 22px;
					font-weight: 650;
					line-height: 32px;
					letter-spacing: 0.0025em;
					text-align: left;
				}
			}
		}
	}
	.you-might-be-interested {
		margin-bottom: 174px;
	}
	@media (width < 1000px) {
		.useful-resources {
			margin: 169px 10% 144px;
			.useful-resources-items {
				flex-direction: column;
				align-items: center;
				gap: 11px;
				.useful-resources-item {
					width: 100%;
				}
			}
		}
	}
	@media (width < 700px) {
		.useful-resources {
			margin: 89px 24px 93px;
			.useful-resources-title {
				font-size: 32px;
				line-height: 36px;
				text-align: left;
				margin-bottom: 35px;
			}
			.useful-resources-items {
				.useful-resources-item {
					min-height: initial;
					.useful-resources-item-header {
						align-items: center;
						margin-bottom: 35px;
						.useful-resources-item-label {
							padding: 3px 12px;
						}
					}
					.useful-resources-item-title {
						font-size: 19px;
						line-height: 24px;
					}
				}
			}
		}
		.you-might-be-interested {
			margin-bottom: 76px;
		}
	}
}
