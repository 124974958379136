@import 'src/styles/palette.module';

.header {
	.linkedin-bigscreen {
		font-size: 20px !important;
		font-weight: 555;
		line-height: 32px;
		letter-spacing: 0.005em !important;
		padding: 2px 36px !important;
	}
	& > .container.main {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: clamp(32px, 5%, 32px);
		padding-left: clamp(24px, 5%, 53px);
		color: $dark-blue;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999999;
		border-radius: 50px 0 0 0;
		@media (width < 1001px) {
			padding: 16px 24px 16px;
			border-radius: 0;
		}
	}

	.header-logo {
		display: flex;
		justify-content: center;
		align-items: center;
		.changed-logo {
			position: relative;
			&.side-menu-opened {
				.side-menu-opened-logo {
					display: block !important;
				}
				.text {
					display: none;
				}
				.circle {
					display: none;
				}
			}
			.side-menu-opened-logo {
				display: none;
			}
			.circle {
				display: none;
			}
			.text {
				display: block;
			}
			&.scrolled {
				.circle {
					display: block;
				}
				.text {
					display: none;
				}
			}
		}
	}

	.menu-button {
		cursor: pointer;
		@media (width < 1001px) {
			width: 49px;
			height: 49px;
		}
	}

	.menu-button:hover {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
	}

	.contact-button {
		color: $turqoise;
		font-size: 20px;
		padding: 15px 45px;
		letter-spacing: 0.1px;
		cursor: pointer;
		margin: 0;
		&.scrolled {
			display: none;
		}
	}

	.button-green.contact-button {
		color: $dark-blue;
		text-transform: none;
		line-height: normal;
		text-transform: none;
		white-space: nowrap;
		font-size: 20px;
		padding: 15px 45px;
		letter-spacing: 0.1px;
		cursor: pointer;
		margin: 0;
	}

	.light.contact-button {
		color: $dark-blue;
		background-color: $turqoise;
	}

	.side-menu a:hover {
		color: $off-white;
	}

	.side-menu {
		box-sizing: border-box;
		padding-top: 121px;
		background-color: $dark-blue;
		height: 720px;
		width: 100%;
		position: fixed;
		z-index: 1;
		top: 0;
		right: 0;
		overflow-x: hidden;
		transform: translate(100%);
		opacity: 1;
		transition: all 0.25s ease-in-out;
		display: block;
		border-radius: 50px 0px 50px 50px;
		@media (width < 1001px) {
			transition: all 0.15s ease-in-out;
		}
		@media (width > 1441px) {
			min-height: unset;
			height: unset;
		}

		.container {
			background: none;
			justify-content: space-between;
			padding-bottom: 0;
			padding-top: 0;
		}
		.header > .container {
			position: sticky;
			top: 0;
			left: 0;
			right: 0;
			padding: clamp(24px, 5%, 53px) clamp(24px, 5%, 53px) 54px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	.menu-wrapper {
		padding: 50px 133px 0 129px;
		gap: 30px;
		justify-content: space-between;
	}

	.menu {
		gap: 24px;
		justify-content: flex-start;
	}

	.menu-item {
		color: $turqoise;
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 54px;
		font-style: normal;
		font-weight: 300;
		line-height: 64px;
		letter-spacing: -0.27px;
		display: flex;
		gap: 17px;
		cursor: pointer;
	}

	.menu-item.hover {
		color: $off-white;
	}

	.menu-item:hover {
		color: $off-white;
		& > .arrow-icon {
			visibility: visible;
		}
	}

	.menu-open {
		// width: 100%;
		opacity: 1;
		transform: none;
		z-index: 999;
	}

	.illustrations-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-end;
		column-gap: inherit;
		&-3 {
			margin-bottom: 30px;
		}
	}

	.small-card:hover {
		-webkit-transform: translateY(-1px);
		transform: translateY(-1px);
		cursor: pointer;
	}

	.header-links {
		& > a.header-link {
			font-family: Degular;
			font-size: 20px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: right;

			color: $turqoise;
			display: block;
		}
	}

	.header-footer {
		display: flex;
		bottom: 0;
		border-top: 1px solid $turqoise;
		margin: 24px 24px 0;
		padding: 20px 0 40px;
		justify-content: space-between;
		align-items: flex-end;
	}

	.menu-wrapper.display-bigscreen {
		display: flex;
	}

	@media (width > 1440px) {
		.header-footer {
			flex-direction: row-reverse;
			margin: 103px 50px 0;
		}
		.header-links {
			display: flex;
			flex-direction: row;
			gap: 64px;
		}
		.illustrations-wrapper {
			flex-direction: row !important;
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		.menu-wrapper.display-smallscreen,
		.menu-wrapper.display-middlescreen {
			display: none;
		}
	}

	@media (width > 700px) {
		.menu-item .arrow-icon {
			visibility: hidden;
			font-feature-settings:
				'dlig' on,
				'ss03' on,
				'salt' on,
				'ss04' on;
			display: inline-block;
		}

		.menu-item.hover .arrow-icon {
			visibility: visible;
		}
	}

	@media (width < 1441px) and (width > 1000px) {
		.header-footer {
			flex-direction: row-reverse;
			margin: 103px 50px 0;
		}
		.header-links {
			display: flex;
			flex-direction: row;
			gap: 64px;
		}

		.illustrations-wrapper {
			justify-content: flex-end;
			align-items: flex-end;
			width: 100%;
			margin-bottom: 0;
		}

		.illustration-inner-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 34px;
			width: fit-content;
		}

		.display-middlescreen {
			display: flex;
		}

		.menu-wrapper.display-bigscreen {
			display: none;
		}

		.side-menu {
			max-height: 775px;
			height: auto;
		}
	}

	@media (width < 1001px) {
		.linkedin-bigscreen {
			font-size: 16px !important;
			line-height: 24px;
			padding: 9px 41px !important;
		}
		.illustrations-wrapper {
			&-1,
			&-2 {
				gap: 14px;
				& > a {
					width: 100%;
					svg {
						width: 100%;
					}
				}
			}
			&-3 {
				margin-bottom: 0;
				gap: 14px;
				& > a {
					width: calc(100% / 2 - 7px);
					max-width: 200px;
					svg {
						width: 100%;
						height: auto;
						rect {
							width: 100%;
						}
					}
				}
			}
		}
		& > .container {
			padding-bottom: 43px;
			padding-left: clamp(24px, 5%, 53px);
		}

		.menu-wrapper.display-bigscreen,
		.menu-wrapper.display-middlescreen {
			display: none;
		}

		.menu-wrapper.display-smallscreen {
			padding: 0 24px;
			display: flex !important;
			flex-direction: column;
			gap: 28px;
		}
		.submenu-wrapper.display-smallscreen {
			padding: 0 30px;
			display: flex !important;
			flex-direction: column;
		}

		.side-menu {
			padding-top: 120px;
			height: 100vh;
			overflow-y: auto;
			border-radius: 0 0 40px 40px;
		}

		.contact-button {
			display: none !important;
		}

		.header-footer {
			margin-top: 83px;
			padding-top: 40px;
		}

		.header-links {
			gap: 32px;
			display: flex;
			flex-direction: column;
		}

		.header-links > a.header-link {
			font-size: 18px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
		}

		.side-menu .container {
			height: 100%;
		}

		.menu-item {
			font-variant-numeric: ordinal;
			font-feature-settings:
				'dlig' on,
				'ss03' on,
				'salt' on,
				'ss04' on;
			font-family: 'Mohol';
			font-size: 32px;
			font-weight: 300;
			line-height: 36px; /* 112.5% */
			letter-spacing: -0.16px;
			cursor: pointer;
		}

		.menu-item-active {
			font-feature-settings:
				'ss02' on,
				'ss01' on;
			color: $off-white;
			font-family: 'Degular';
			font-size: 18px;
			font-weight: 555;
			line-height: 24px; /* 133.333% */
			letter-spacing: 1.5px;
			text-transform: uppercase;
			margin-bottom: 30px;
			cursor: pointer;
		}

		.contact-link {
			font-variant-numeric: ordinal;
			font-feature-settings:
				'dlig' on,
				'ss03' on,
				'salt' on,
				'ss04' on;
			color: $turqoise;
			/* H3/Mobile */
			font-family: Mohol;
			font-size: 32px;
			font-style: normal;
			font-weight: 300;
			line-height: 36px; /* 112.5% */
			letter-spacing: -0.16px;
		}

		.submenu-wrapper {
			margin: 0 24px;
		}
	}

	@media (width < 451px) {
		.menu-wrapper {
			padding: 0 !important;
			margin: 0 24px;
		}

		.submenu-wrapper {
			padding: 0 !important;
			margin: 0 24px !important;
		}
	}
}
