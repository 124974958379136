@import 'src/styles/palette.module';

.resources {
	overflow: hidden;
	& > .title-card {
		& > .container {
			height: initial;
		}
	}
	.title-card {
		border-radius: 0;
	}
	.title-arrow {
		margin-bottom: 60px;
	}
	.flex-center {
		align-items: center;
	}
	.flex-center.flex-column {
		margin: 0 133px;
	}
	.title-card-title {
		margin-left: 0;
		font-size: 72px;
		font-weight: 300;
		line-height: 80px;
		letter-spacing: -0.01em;
		text-align: center;
		max-width: 1018px;
		width: 100%;
	}
	.card-text {
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.0025em;
		text-align: center;
		padding: 38px 0 72px;
		max-width: 878px;
		width: 100%;
		& > a {
			color: $dark-blue;
			border-bottom: 1.5px solid $dark-blue;
		}
	}
	.img-wrapper {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		& > img {
			width: 100%;
			max-width: 878px;
			margin: -250px auto 0;
		}
	}
	@media (width < 1200px) {
		.img-wrapper {
			& > img {
				width: calc(100% - 20%);
				max-width: 878px;
				margin: -250px 10% 0;
			}
		}
	}
	@media (width < 1000px) {
		.flex-center.flex-column {
			margin: 0 10%;
		}
	}
	@media (width < 900px) {
		.flex-center.flex-column {
			margin: 0 24px 50px;
		}
		.title-card-title {
			font-size: 40px;
			line-height: 40px;
		}
		.card-text {
			font-size: 22px;
			line-height: 32px;
			padding: 12px 0 0;
		}
		.img-wrapper {
			& > img {
				width: 50%;
				height: fit-content;
				margin: -300px -60% 0 0;
			}
		}
		.title-card {
			.title-arrow {
				margin-bottom: 0;
			}
		}
	}
	@media (width < 600px) {
		.img-wrapper {
			& > img {
				width: auto;
				height: 224px;
				margin: -300px -45% 0 0;
			}
		}
	}
}
