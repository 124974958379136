@import 'src/styles/palette.module';

.outcomes-base {
	.container {
		padding: 133px 133px 184px;
		box-sizing: border-box;
		.card-title {
			margin-bottom: 63px;
		}
	}

	.flex {
		display: flex;
		flex-direction: row;
		gap: 34px;
	}

	.flex-column {
		gap: 34px;
	}

	.outcomes-card {
		width: 50%;
		box-sizing: border-box;
		background-color: #fff;
		padding: 38px 42px 34px 40px;
		border-radius: 20px;
		box-shadow: 0px 15px 20px 0px rgba(0, 196, 149, 0.1);
	}

	.card-flex {
		display: flex;
		flex-direction: row;
		gap: 30px;
	}

	.outcomes-card-title {
		text-transform: uppercase;
		max-width: 280px;
		font-size: 20px;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.02em;
		text-align: left;
	}

	.list {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.005em;
		text-align: left;

		& > ul {
			margin-block-start: 0;
			margin-block-end: 0;
			margin-top: 16px;
			padding-inline-start: 25px;
			max-width: 280px;
			box-sizing: border-box;
		}
	}

	li::marker {
		height: 3px;
		width: 3px;
		font-size: 13px;
	}

	li {
		list-style-type: square;
		margin-bottom: 10px;
		padding-left: 10px;
		max-width: 280px;
	}

	@media (width < 1200px) {
		.flex {
			flex-direction: column;
		}

		.outcomes-card {
			width: 100%;
			max-width: 400px;
		}

		.card-flex {
			flex-direction: row;
			gap: 50px;
		}

		.container {
			padding: 0 100px 130px;
		}

		.card-title {
			font-size: 40px;
			margin-left: 0;
			margin-bottom: 20px;
		}
	}

	@media (width < 1200px) {
		.card-flex {
			flex-direction: column;
			gap: 50px;
		}

		.outcomes-img {
			width: 50%;
			margin: auto;
		}
	}

	@media (width < 700px) {
		.container {
			padding: 80px 48px 100px;
		}

		.card-title {
			font-size: 32px;
			margin-bottom: 40px;
		}

		.outcomes-img {
			width: 60%;
			margin: auto;
		}
	}

	@media (width < 450px) {
		.container {
			padding: 80px 24px 100px;
		}

		.card-title {
			font-size: 32px;
			margin-bottom: 40px;
		}

		.outcomes-img {
			width: 65%;
			margin: auto;
		}

		.flex {
			gap: 25px;
		}

		.flex-column {
			gap: 25px;
		}

		.outcomes-card {
			padding: 24px 24px 40px;
		}

		.card-flex {
			gap: 40px;
		}
	}
}
