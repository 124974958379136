@import 'src/styles/palette.module';

.demo-description {
	max-width: 422px;
	font-size: 22px;
	font-weight: 500;
	line-height: 32px;
	letter-spacing: 0.0025em;
	margin: 260px 0 40px 30px;
	margin-left: auto;
	text-align: right;
	@media (width < 601px) {
		margin: 133px 50px 20px 30px;
		margin-left: auto;
	}
	@media (width < 501px) {
		margin: 133px 24px 34px 24px;
		font-size: 19px;
		line-height: 24px;
	}
}

.MuiMenu-paper {
	border-radius: 25px !important;
	background: $off-white !important;
	color: $dark-blue !important;
	border: 2px solid #00c495;
}

.demo-form {
	background: $dark-blue;
	padding: 80px 114px;
	border-radius: 50px;
	& > form {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		.demo-list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
			gap: 40px 34px;
		}
		.demo-checkboxes {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 34px;
			margin-top: 64px;

			& > span {
				font-size: 26px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0.0025em;
				color: $turqoise;
				width: calc(50% - 17px);
			}
			& .MuiFormGroup-root {
				width: calc(50% - 17px);
				gap: 2em;
			}
			& .MuiFormControlLabel-label {
				color: $turqoise;
				font-family: 'Degular';
				font-size: 16px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
			}
		}
		.demo-submit {
			margin-top: 64px;
			padding: 18px 91px;
			font-size: 20px;
			font-family: 'Degular';
			font-weight: 555;
			line-height: 32px;
			letter-spacing: 0.005em;
			text-align: center;
			color: $turqoise;
			border: 2px solid $turqoise;
			border-radius: 50px;
			background: transparent;
			align-self: center;
		}
		.snackbar-container {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1000;
		}

		.alert {
			background-color: white;
			color: black;
			font-family: inherit;
		}
	}
	@media (width < 1001px) {
		padding: 50px;
		& > form {
			.demo-list {
				gap: 2em;
			}
			.MuiTextField-root,
			.MuiFormControl-root {
				width: calc(50% - 1em);
			}
		}
	}
	@media (width < 601px) {
		padding: 64px 24px;
		margin-right: 50px;
		border-radius: 40px;
		& > form {
			.demo-list {
				gap: 0;
				flex-direction: column;
			}
			.MuiTextField-root,
			.MuiFormControl-root {
				width: 100%;
				margin-bottom: 40px;
			}
			.demo-checkboxes {
				margin-top: 0;
				margin-left: 30px;
				flex-direction: column;
				align-items: flex-start;
				gap: 0;
				& > span {
					width: 100%;
				}
				& .MuiFormGroup-root {
					width: 100%;
				}
			}
			.demo-submit {
				padding: 20px 101px;
				font-size: 18px;
				line-height: 24px;
				margin-top: 56px;
			}
		}
	}
	@media (width < 501px) {
		padding: 60px 24px;
		margin-right: 0;
		& > form {
			.demo-checkboxes {
				& > span {
					font-size: 22px;
					line-height: 32px;
					margin-bottom: 47px;
				}
				& .MuiFormGroup-root {
					gap: 3em;
				}
			}
		}
	}
}

.demo-underform-text {
	max-width: 718px;
	margin: 37px auto 145px;
	font-size: 17px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.005em;
	text-align: center;
	color: $dark-blue;
	@media (width < 601px) {
		margin: 37px 50px 85px 0;
	}
	@media (width < 501px) {
		font-size: 15px;
		margin: 37px 24px 85px 24px;
	}
}

.demo-get-in-touch {
	max-width: 878px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	& > span {
		font-family: Mohol;
		font-size: 42px;
		font-weight: 300;
		line-height: 48px;
		text-align: center;
		color: $dark-blue;
		padding-bottom: 48px;
		font-feature-settings:
			'dlig' on,
			'salt' on,
			'ss03' on,
			'ss04' on;
	}
	& .button-dark {
		font-size: 20px;
		padding: 20px 60px;
	}
	@media (width < 601px) {
		margin: 37px 50px 0 0;
	}
	@media (width < 501px) {
		margin: 37px 24px 0 24px;
		& > span {
			font-size: 26px;
			line-height: 32px;
			padding-bottom: 30px;
		}
		.button-dark {
			padding: 24px 65px;
			font-size: 18px;
			line-height: 24px;
		}
	}
}

.demo-card.container {
	padding: 0 133px 183px;
	box-sizing: border-box;

	@media (width < 1200px) {
		padding: 0 100px 130px;
	}

	@media (width < 700px) {
		padding: 0 48px 110px;
	}

	@media (width < 450px) {
		padding: 0 24px 100px;
	}
}

.demo-card {
	.try-demo button {
		color: $dark-blue;
	}

	.wrapper {
		background-color: $turqoise;
		box-sizing: border-box;
		border-radius: 50px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.planning {
		background-color: $dark-blue;
		border-radius: 50px;
		color: $turqoise;
		padding: 100px 57px 60px;
		width: 36%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
	}

	.planning-title {
		font-variant-numeric: ordinal;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-family: Mohol;
		font-size: 42px;
		font-style: normal;
		font-weight: 300;
		line-height: 42px;
		margin-bottom: 30px;
	}

	.planning-subtitle {
		font-size: 22px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.055px;
	}

	.planning-subtitle a {
		color: $turqoise;
	}

	.img-wrapper {
		padding: 72px 74px 56px 74px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		max-width: 496px;
	}

	.img {
		width: 100%;
		margin-bottom: 54px;
		border-radius: 20px;
	}

	.try-demo {
		display: flex;
		justify-content: center;
	}

	@media (width < 1200px) {
		.wrapper {
			flex-direction: column;
		}

		.img-wrapper {
			padding: 92px 94px 56px;
			max-width: none;
		}

		.planning {
			box-sizing: border-box;
			width: 100%;
			padding: 100px 120px 70px;
		}

		.planning-title {
			line-height: 47px;
		}
	}

	@media (width < 700px) {
		.wrapper {
			border-radius: 45px;
		}

		.img-wrapper {
			padding: 52px 54px 40px;
		}

		.img {
			border-radius: 10px;
		}

		.planning {
			border-radius: 45px;
			padding: 80px 60px 50px;
		}

		.planning-title {
			font-size: 36px;
			line-height: 36px;
		}

		.planning-subtitle {
			font-size: 22px;
			line-height: 30px;
		}
	}

	@media (width < 450px) {
		.wrapper {
			border-radius: 40px;
		}

		.img-wrapper {
			padding: 32px 32px 40px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
			border-radius: 40px;
		}

		.img {
			margin-bottom: 40px;
		}

		.planning {
			box-sizing: border-box;
			border-radius: 40px;
			padding: 38px 32px 32px 33px;
			width: 100%;
		}

		.planning-title {
			font-size: 26px;
			font-weight: 300;
			line-height: 32px;
		}

		.planning-subtitle {
			font-size: 19px;
			font-weight: 500;
			line-height: 24px; /* 126.316% */
			letter-spacing: 0.5px;
		}

		.try-demo button {
			font-size: 18px;
			padding: 15px 27px;
			letter-spacing: 1px;
		}
	}
}
