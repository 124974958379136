@import 'src/styles/palette.module';

.blog {
	.flex-center {
		align-items: center;
	}
	.flex-center.flex-column {
		margin: 0 133px;
	}
	.title-card > .container {
		height: auto;
	}
	.title-card-title {
		margin-left: 0;
		max-width: 100%;
		font-size: 72px;
		font-weight: 300;
		line-height: 80px;
		letter-spacing: -0.01em;
		text-align: center;
	}
	.card-text {
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.0025em;
		text-align: center;
		padding: 46px 0 56px;
		max-width: 877px;
	}
	.img-wrapper {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		& > img {
			max-height: 525px;
			max-width: 878px;
			margin: -150px auto 0;
			@media (width < 1400px) {
				margin: -250px auto 0;
			}
		}
	}
	.blog-details.extra-space-before {
		padding-top: 59px;
	}
	.blog-details {
		display: flex;
		align-items: center;
		margin-bottom: 120px;

		.blog-author {
			display: flex;
			align-items: center;
			& > img {
				width: 48px;
				height: 48px;
				border-radius: 100%;
			}
			& > span {
				color: $dark-blue;
				font-family: Degular;
				font-size: 16px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				padding-left: 19px;
				text-transform: uppercase;
				margin-right: 15px;
			}
		}
		.blog-date {
			margin-left: 41px;
			font-family: Degular;
			font-size: 16px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: right;
			text-transform: uppercase;
			color: $dark-blue;
		}
	}
	.blog-content {
		margin: 118px 167px 57px;
		color: $dark-blue;

		.lead {
			font-size: 26px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.065px;
			margin-bottom: 59px;
		}

		& > section {
			margin-bottom: 23px;
			& h6,
			h2 {
				margin-block-start: 0;
				margin-block-end: 0;
				color: $dark-blue;
				font-family: Degular;
				font-size: 30px;
				font-weight: 555;
				line-height: 32px;
				letter-spacing: 0.02em;
				text-align: left;
				margin-bottom: 59px;
				margin-top: 59px;
				text-transform: uppercase;
			}
			& h3 {
				font-size: 20px;
				font-style: normal;
				font-weight: 555;
				line-height: 24px; /* 120% */
				letter-spacing: 0.4px;
				text-transform: uppercase;
				margin-bottom: 20px;
			}
			& h5 {
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
				letter-spacing: 0.32px;
				text-transform: uppercase;
			}
			& p {
				margin-block-start: 0;
				margin-block-end: 0;
				color: $dark-blue;
				font-family: Degular;
				font-size: 22px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0.0025em;
				text-align: left;
				margin-bottom: 20px;
			}
			& h4 {
				margin-block-start: 0;
				margin-block-end: 0;
				color: $dark-blue;
				font-family: Mohol;
				font-size: 42px;
				font-weight: 300;
				line-height: 48px;
				text-align: left;
				max-width: 992px;
				margin: 59px 0 59px 114px;
				padding: 24px 0;
				font-feature-settings:
					'dlig' on,
					'salt' on,
					'ss03' on,
					'ss04' on;
			}
			& .heading-7 {
				font-size: 17px;
				font-style: normal;
				font-weight: 500;
				line-height: 22px; /* 129.412% */
				letter-spacing: 0.085px;
			}
			& .extra-space-before {
				margin-top: 59px;
			}
			& a {
				color: $turqoise;
				text-decoration: underline;
			}
			& img {
				width: 100%;
				border-radius: 40px;
				overflow: hidden;
				max-width: 878px;
				margin: 103px auto 110px;
				width: 100%;
				display: block;
			}
		}
	}
	.blog-link {
		color: $dark-blue;
		font-family: Mohol;
		font-size: 42px;
		font-weight: 300;
		line-height: 48px;
		text-align: left;
		margin: 89px 133px 155px auto;
		display: block;
		width: fit-content;
		padding-bottom: 18px;
		border-bottom: 2px solid $dark-blue;
		font-feature-settings:
			'dlig' on,
			'salt' on,
			'ss03' on,
			'ss04' on;
	}
	@media (width < 1400px) {
		.blog-details {
			margin-bottom: 280px;
		}
	}
	@media (width < 1000px) {
		.flex-center.flex-column {
			margin: 0 10%;
		}
		.blog-content {
			margin: 118px 10% 57px;
		}
	}
	@media (width < 700px) {
		.card-title {
			padding-right: 0;
		}
		.flex-center.flex-column {
			margin: 0 24px 120px;
		}
		.title-card-title {
			font-size: 36px;
			line-height: 40px;
		}
		.card-text {
			font-size: 19px;
			line-height: 24px;
			padding: 33px 0 42px;
		}
		.img-wrapper {
			& > img {
				width: 100%;
				margin: -170px auto 0;
			}
		}
		.title-card {
			.title-arrow {
				display: none;
			}
		}
		.blog-details {
			margin-bottom: 50px;
		}
		.blog-content {
			margin: 80px 0 75px;
			& > section {
				margin-bottom: 23px;
				& .lead {
					margin: 48px 24px;
					font-size: 22px;
					font-weight: 500;
					line-height: 32px; /* 145.455% */
					letter-spacing: 0.11px;
				}
				& h2 {
					font-size: 20px;
					font-weight: 555;
					line-height: 24px; /* 120% */
					letter-spacing: 0.4px;
					text-transform: uppercase;
					margin: 48px 24px;
				}
				& h3 {
					font-size: 18px;
					font-style: normal;
					font-weight: 555;
					line-height: 24px; /* 133.333% */
					letter-spacing: 0.36px;
					text-transform: uppercase;
					margin: 0 24px 20px;
				}
				& h5 {
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 150% */
					letter-spacing: 0.32px;
					text-transform: uppercase;
					margin-right: 24px;
					margin-left: 24px;
				}
				& h6 {
					font-size: 20px;
					line-height: 24px;
					margin: 0 24px 48px;
				}
				& .heading-7 {
					font-size: 18px;
					font-style: normal;
					font-weight: 555;
					line-height: 24px; /* 133.333% */
					letter-spacing: 0.36px;
					text-transform: uppercase;
					margin: 0 24px 20px;
				}
				& p {
					font-size: 19px;
					line-height: 24px;
					margin: 0 24px 20px;
				}
				& h4 {
					font-size: 26px;
					line-height: 32px;
					margin: 48px 24px 48px 63px;
				}
				& img {
					border-radius: 30px;
					margin: 44px 0;
					width: 100%;
				}
			}
		}
		.blog-link {
			font-size: 22px;
			line-height: 24px;
			margin: 80px 24px 80px auto;
		}
	}
}
