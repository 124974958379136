@import 'src/styles/palette.module';

.specific-use-case {
	.problem-solution {
		margin: 168px 0 142px;
		max-width: 1440px;
		margin-left: auto;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		@media (width > 1800px) {
			max-width: 70vw;
		}
		.problem-solution-block {
			padding: 74px 133px 77px 80px;
			max-width: 703px;
			width: 100%;
			border-radius: 50px 0 0 50px;
			background: $turqoise;
			@media (width > 1800px) {
				padding: 74px calc(((100vw - 1440px) / 2)) 77px 80px;
			}
			&:first-child {
				padding: 74px 106px 77px 133px;
				border-radius: 0;
				background: transparent;
			}
			.problem-solution-title {
				font-family: Mohol;
				font-size: 42px;
				font-weight: 300;
				line-height: 48px;
				text-align: left;
				color: $dark-blue;
				display: flex;
				gap: 25px;
				align-items: center;
				font-feature-settings:
					'dlig' on,
					'salt' on,
					'ss03' on,
					'ss04' on;
			}
			.problem-solution-text {
				margin-top: 30px;
				font-family: Degular;
				font-size: 22px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0.0025em;
				text-align: left;
				color: $dark-blue;
				& > b {
					font-weight: 650;
				}
			}
			.problem-solution-button {
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				color: $dark-blue;
				margin-top: 70px;
				display: block;
				width: fit-content;
				margin-left: auto;
				text-transform: uppercase;
				border-bottom: 1.5px solid $dark-blue;
			}
		}
	}
	@media (width < 1200px) {
		.problem-solution {
			flex-direction: column;
			.problem-solution-block {
				padding: 74px 106px 77px 133px !important;
				border-radius: 50px;
			}
		}
	}
	@media (width < 1000px) {
		.problem-solution {
			flex-direction: column;
			.problem-solution-block {
				padding: 50px 10% 50px 10% !important;
				border-radius: 50px;
			}
		}
	}
	@media (width < 900px) {
		.problem-solution {
			margin: 19px 0 79px;
			.problem-solution-block {
				padding: 50px 24px !important;
				border-radius: 40px;
				width: 100%;
				box-sizing: border-box;
				.problem-solution-title {
					font-size: 32px;
					line-height: 36px;
					gap: 17px;
					& > svg {
						width: 32px;
						height: 32px;
					}
				}
				.problem-solution-text {
					margin-top: 26px;
					font-size: 19px;
					line-height: 24px;
				}
				.problem-solution-button {
					margin-top: 54px;
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}
}
