@import 'src/styles/palette.module';

.footer.background {
	border-radius: 50px 50px 0 0;
	background-color: $dark-blue;
}

.footer .container {
	color: $off-white;
	padding: 46px 53px 24px;
}

.newsletter {
	width: 540px;
	margin-right: 142px;
}

.email-input {
	width: 100%;
	.MuiInputBase-root {
		height: 100%;
	}
	.MuiTextField-root {
		width: 100% !important;
	}
}

.footer .card-title {
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 54px;
	font-style: normal;
	font-weight: 300;
	line-height: 64px;
	letter-spacing: -0.27px;
}

.footer .card-subtitle {
	font-family: Degular;
	font-size: 22px;
	font-style: normal;
	line-height: 32px;
	padding: 33px 0 57px 0;
	font-weight: 500;
}

.footer .linkedin-bigscreen {
	padding: 6px 28px !important;
}

.footer input {
	width: 70%;
	border-radius: 100px;
	background: $off-white;
	padding: 31px 80px 27px 35px;
	border: none;
}

#subscribe-btn {
	z-index: 3;
	width: 48%;
	border-radius: 100px;
	background: $turqoise;
	padding: 20px 45px 20px 45px;
	border: none;
	font-family: Degular;
	font-size: 24px;
	font-style: normal;
	font-weight: 555;
	line-height: 32px;
	letter-spacing: 0.48px;
	text-transform: capitalize;
	margin-left: -72px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $dark-blue;
	& .newsletter-button {
		display: flex !important;
		justify-content: center;
		align-items: center;
		margin-left: 9px;
		color: $dark-blue;
	}
	& .newsletter-button-text {
		display: block;
	}
	&:disabled {
		cursor: not-allowed;
	}
}

.footer-logo {
	padding: 117px 0 40px 0;
}

.footer-legal-wrapper {
	align-items: end;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto;

}

.footer-legal {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: $off-white;
	opacity: 0.5;
}

.footer .footer-info a {
	color: $off-white;
	text-decoration: none;
	font-family: Degular;
	font-size: 20px !important;
	font-style: normal;
	font-weight: 555;
	line-height: 24px;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.footer .footer-pages {
	text-decoration: none;
	transition: color 0.3s;

	&:hover {
		opacity: 0.8;
	}
}

.footer .footer-pages a {
	@extend .footer-legal;
}

.footer a {
	text-decoration: none;
}

.footer .links {
	margin-top: 106px;
	// width: 55%;
	flex: 1;
	justify-content: space-between;
}

.footer .links .flex-column {
	max-width: 200px;
}

.footer-info {
	display: flex;
}

.linkedin-smallscreen,
.linkedin-bigscreen {
	border-radius: 50px;
	border: 2px solid $turqoise;
	color: $turqoise !important;
	padding: 10px 47px !important;
	width: fit-content;
	font-size: 16px !important;
	letter-spacing: 0.32px !important;
	margin: 27px 0 0 0 !important;
}

.links-smallscreen,
.linkedin-smallscreen {
	display: none;
}

@media (width < 1200px) {
	#subscribe-btn {
		width: 85px;
		& .newsletter-button-text {
			display: none;
		}
	}
}

@media (min-width: 901px) and (max-width: 1024px) {
	.footer-info {
		gap: 64px;
		.newsletter {
			margin-right: 0;
			width: 40%;
		}
		.links {
			width: 60%;
			.flex-column {
				max-width: 170px;
			}
		}
	}
	.footer-logo-wrapper {
		justify-content: center;
		gap: 20px;
	}
}

@media (width < 900px) {
	.footer.background {
		border-radius: 40px 40px 0 0;
	}
	.links.display-bigscreen {
		display: none;
	}
	#subscribe-btn {
		width: 48%;
		& .newsletter-button-text {
			display: block;
		}
	}
	.footer .container {
		padding: 48px 24px 32px;
	}
	.footer-info {
		flex-direction: column !important;
	}

	.newsletter {
		width: 100%;
	}

	.footer .card-title {
		margin: 0 0 19px 0;
		font-size: 32px;
		line-height: 36px;
		letter-spacing: -0.16px;
		width: 100%;
	}

	.footer .card-subtitle {
		padding: 0;
		margin: 0 0 40px 0;
		font-size: 19px;
		font-style: normal;
		line-height: 24px;
		letter-spacing: 0.095px;
	}

	.footer input {
		width: 100%;
	}

	.links-smallscreen {
		display: flex;
		flex-direction: row;
		gap: 37px;
		margin-top: 57px;
		width: 100%;
	}

	.link-column {
		display: flex;
		flex-direction: column;
		max-width: 180px;
	}

	.footer .link-column a {
		font-family: Degular;
		font-size: 18px !important;
		font-style: normal;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.36px;
		text-transform: uppercase;
	}

	.footer .linkedin-smallscreen {
		display: block;
		border-radius: 50px;
		border: 2px solid $turqoise;
		color: $turqoise !important;
		padding: 10px 47px !important;
		width: fit-content;
		font-size: 16px !important;
		letter-spacing: 0.32px !important;
		margin: 20px 0 0 0 !important;
	}

	.footer-logo {
		padding: 56px 0 29px 0;
	}

	.fooer-link,
	.footer-legal {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0.32px;
		text-transform: uppercase;
		opacity: 0.5;
	}

	.footer-pages {
		transition: color 0.3s;
	
		&:hover {
			opacity: 0.8;
		}
	}
}


@media (width < 700px) {
	#subscribe-btn {
		width: 85px;
		& .newsletter-button-text {
			display: none;
		}
	}
}

@media (width < 430px) {
	.footer-legal-wrapper {
		flex-direction: column !important;
		align-items: center;
	}
}
