@import 'src/styles/palette.module';

.about-us {
	.our-values {
		background: $turqoise;
		.our-values-block {
			padding: 118px 80px 127px 133px;
			border-radius: 0 50px 50px 0;
			background: $off-white;
			max-width: 1387px;
			margin-right: 53px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			box-sizing: border-box;
			@media (width > 1800px) {
				padding: 118px 80px 127px calc((100vw - 1440px) / 2 + 133px);
				max-width: 70vw;
			}
			.our-values-subtitle {
				color: $dark-blue;
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				text-transform: uppercase;
				margin-bottom: 19px;
			}
			.our-values-title {
				color: $dark-blue;
				font-family: Mohol;
				font-size: 48px;
				font-weight: 300;
				line-height: 64px;
				text-align: left;
				max-width: 912px;
				font-feature-settings:
					'dlig' on,
					'salt' on,
					'ss03' on,
					'ss04' on;
				& > span {
					border-bottom: 2px solid $dark-blue;
				}
			}
			.our-values-description {
				max-width: 450px;
				margin-top: 128px;
				color: $dark-blue;
				font-family: Degular;
				font-size: 22px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0.0025em;
				text-align: left;
				align-self: flex-end;
				& > b {
					font-weight: 650;
				}
			}
		}
	}
	@media (width < 1000px) {
		.our-values {
			.our-values-block {
				padding: 118px 80px 127px 10%;
			}
		}
	}
	@media (width < 900px) {
		.our-values {
			.our-values-block {
				padding: 55px 36px 73px 24px;
				margin-right: 24px;
				.our-values-subtitle {
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 21px;
				}
				.our-values-title {
					font-size: 26px;
					line-height: 32px;
				}
				.our-values-description {
					margin-left: 40px;
					font-size: 19px;
					line-height: 24px;
					margin-top: 43px;
				}
			}
		}
	}
}
