@import 'src/styles/palette.module';

.specific-use-case {
	.data-privacy {
		margin: 142px 133px 192px;
		.data-privacy-block {
			margin: 0 114px 170px 0;
			.data-privacy-subtitle {
				color: $dark-blue;
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				margin-bottom: 19px;
				text-transform: uppercase;
			}
			.data-privacy-title {
				font-family: Mohol;
				font-size: 48px;
				font-weight: 300;
				line-height: 64px;
				text-align: left;
				color: $dark-blue;
				& > a {
					color: $dark-blue;
					border-bottom: 2px solid $dark-blue;
				}
			}
		}
		.data-privacy-row {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.data-privacy-img {
				max-width: 420px;
				& > img {
					max-width: inherit;
					margin: 0;
				}
			}
			.data-privacy-content {
				max-width: 490px;
				margin-left: 100px;
				.data-privacy-text {
					color: $dark-blue;
					font-family: Degular;
					font-size: 22px;
					font-weight: 500;
					line-height: 32px;
					letter-spacing: 0.0025em;
					text-align: left;
					margin-bottom: 40px;
					& > b {
						font-weight: 650;
					}
				}
				.data-privacy-button {
					margin-top: 20px;
					text-transform: uppercase;
					font-family: Degular;
					font-size: 20px;
					font-weight: 555;
					line-height: 32px;
					letter-spacing: 0.005em;
					padding: 20px 60px;
				}
			}
		}
	}
	@media (width < 1200px) {
		.data-privacy {
			.data-privacy-row {
				flex-direction: column;
				.data-privacy-content {
					margin-top: 50px;
					max-width: 690px;
				}
			}
		}
	}
	@media (width < 1000px) {
		.data-privacy {
			margin: 142px 10% 192px;
		}
	}
	@media (width < 900px) {
		.data-privacy {
			margin: 79px 24px 96px;
			.data-privacy-block {
				margin: 0 0 60px;
				.data-privacy-subtitle {
					font-size: 18px;
					line-height: 24px;
				}
				.data-privacy-title {
					font-size: 26px;
					line-height: 32px;
				}
			}
			.data-privacy-row {
				.data-privacy-img {
					& > img {
						width: auto;
					}
				}
				.data-privacy-content {
					margin: 39px 0 0 39px;
					.data-privacy-text {
						font-size: 19px;
						line-height: 24px;
						margin-bottom: 20px;
					}
					.data-privacy-button {
						font-size: 18px;
						line-height: 24px;
						margin-left: auto;
						padding: 20px 80px;
					}
				}
			}
		}
	}
}
