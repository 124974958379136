$transition-duration: 350ms;
$transition-timing: ease-in-out;

.page-enter {
	opacity: 0;
	transform: translateY(-10px);
}

.page-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition:
		opacity $transition-duration $transition-timing,
		transform $transition-duration $transition-timing;
}

.page-exit {
	opacity: 1;
	transform: translateY(0);
}

.page-exit-active {
	opacity: 0;
	transform: translateY(-10px);
	transition:
		opacity $transition-duration $transition-timing,
		transform $transition-duration $transition-timing;
}
