@import 'src/styles/palette.module';

.privacy-enhancing-technologies {
	.key-privacy-enhancing-technologies {
		background: $dark-blue;
		border-radius: 50px;
		padding: 114px 133px 144px;
		& > .container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.key-privacy-enhancing-technologies-title {
			font-family: Mohol;
			font-size: 72px;
			font-weight: 300;
			line-height: 80px;
			letter-spacing: -0.01em;
			text-align: center;
			color: $off-white;
			max-width: 1140px;
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
		}
		.key-privacy-enhancing-technologies-subtitle {
			font-family: Degular;
			font-size: 22px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.0025em;
			text-align: center;
			color: $off-white;
			margin: 21px 0 97px;
			max-width: 946px;
			& > a {
				color: $off-white;
				text-decoration: underline;
			}
		}
		.key-privacy-enhancing-technologies-item {
			width: 100%;
			padding: 40px 47px;
			border-radius: 30px;
			margin-bottom: 24px;
			box-sizing: border-box;
			&-0,
			&-2,
			&-3,
			&-4 {
				.key-privacy-enhancing-technologies-item-content {
					& > div:first-child {
						max-width: 561px;
						width: 100%;
						box-sizing: border-box;
					}
				}
			}
			&-1 {
				.key-privacy-enhancing-technologies-item-content {
					& > div:first-child {
						max-width: 755px;
					}
				}
			}

			.key-privacy-enhancing-technologies-item-header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				.key-privacy-enhancing-technologies-item-title {
					font-family: Mohol;
					font-size: 32px;
					font-weight: 300;
					line-height: 36px;
					letter-spacing: 0.0025em;
					text-align: left;
					color: $dark-blue;
					align-self: center;
					font-feature-settings:
						'dlig' on,
						'salt' on,
						'ss03' on,
						'ss04' on;
				}
				.key-privacy-enhancing-technologies-item-button {
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					transform: rotate(0);
					&.opened {
						transform: rotate(45deg);
					}
				}
			}
			&-1 {
				.key-privacy-enhancing-technologies-item-content {
					& > div {
						width: auto !important;
						@media (width < 1300px) {
							width: 100% !important;
						}
					}
				}
			}
			.key-privacy-enhancing-technologies-item-content {
				border-top: 1px solid $dark-blue;
				padding: 36px 0 24px;
				font-family: Degular;
				font-size: 20px;
				font-weight: 529;
				line-height: 26px;
				letter-spacing: 0.0075em;
				text-align: left;
				color: $dark-blue;
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				margin-top: 40px;
				.button-dark {
					padding: 20px 66px;
					font-size: 20px;
					line-height: 32px;
					margin-top: 40px;
				}
				& > div {
					& > .key-privacy-enhancing-technologies-item-content-block {
						margin-bottom: 56px;
						&:last-child {
							margin-bottom: 0;
						}
						& > div {
							margin-bottom: 15px;
						}
						& b {
							font-weight: 700;
						}
					}
				}
				& > div {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-end;
					&:last-child {
						margin-left: 20px;
					}
					& > div.img-wrapper {
						max-width: 413px;
						margin-top: 50px;
						& > img {
							max-width: inherit;
							margin: 0;
						}
					}
				}
				.key-privacy-enhancing-technologies-item-content-title {
					margin-bottom: 15px;
				}
			}
		}
	}
	@media (width < 1000px) {
		.key-privacy-enhancing-technologies {
			padding: 114px 10% 144px;
		}
	}
	@media (width < 700px) {
		.key-privacy-enhancing-technologies {
			padding: 61px 24px 80px;
			border-radius: 40px;
			.key-privacy-enhancing-technologies-title {
				font-size: 36px;
				line-height: 40px;
			}
			.key-privacy-enhancing-technologies-subtitle {
				margin: 26px 0 65px;
				font-size: 19px;
				line-height: 24px;
			}
			.key-privacy-enhancing-technologies-item {
				padding: 20px;
				.key-privacy-enhancing-technologies-item-header {
					.key-privacy-enhancing-technologies-item-title {
						font-size: 22px;
						line-height: 24px;
						margin-right: 20px;
					}
					.key-privacy-enhancing-technologies-item-button {
						& > svg {
							width: 32px;
							height: 32px;
						}
					}
				}
				.key-privacy-enhancing-technologies-item-content {
					flex-direction: column;
					padding: 30px 0 28px;
					margin-top: 30px;
					font-size: 17px;
					line-height: 22px;
					.button-dark {
						padding: 20px 50px;
						font-size: 18px;
						line-height: 24px;
					}
					& > div {
						justify-content: flex-start;
						align-items: flex-start;
						&:last-child {
							margin-left: 0;
							margin-top: 20px;
						}
						& > a {
							align-self: center;
							& > button {
								font-size: 18px;
								line-height: 24px;
							}
						}
						& > .key-privacy-enhancing-technologies-item-content-block {
							margin-bottom: 20px;
						}
					}
				}
			}
		}
	}
}
