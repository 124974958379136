@import 'src/styles/palette.module';

.how-it-works {
	.color-block {
		background: $turqoise;
		padding: 57px 80px 66px 133px;
		border-radius: 0 50px 50px 0;
		max-width: 1387px;
		width: calc(100% - 90px);
		box-sizing: border-box;
		@media (width > 1800px) {
			padding: 57px 80px 66px calc((100vw - 1440px) / 2 + 133px);
			max-width: 70vw;
		}
		.color-block-title {
			color: $dark-blue;
			font-family: Mohol;
			font-size: 48px;
			font-weight: 300;
			line-height: 64px;
			text-align: left;
			max-width: 684px;
			width: 100%;
			& > span {
				border-bottom: 2px solid $dark-blue;
			}
		}
		.color-block-subtitle {
			color: $dark-blue;
			font-family: Degular;
			font-size: 20px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: right;
			margin-top: 125px;
			margin-left: auto;
			text-transform: uppercase;
		}
	}
	@media (width < 1000px) {
		.color-block {
			padding: 57px 80px 66px 24px;
			width: calc(100% - 24px);
			margin-right: 24px;
		}
	}

	@media (width < 700px) {
		.color-block {
			padding: 44px 39px 57px 24px;
			margin: 0 0 0 0;
			.color-block-title {
				font-size: 26px;
				line-height: 32px;
			}
			.color-block-subtitle {
				margin-top: 90px;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}
}
