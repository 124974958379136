@import 'src/styles/palette.module';

.collaboration-card.background {
	background-image: linear-gradient(to left, $dark-blue 50%, transparent 50%);
	background-color: $off-white;
	padding-bottom: 0;
	margin-left: 0;
	margin-bottom: 118px;
}

.collaboration-card .flex-row {
	gap: 0%;
}

.collaboration-card .card-title {
	color: $off-white;
	padding: 118px 0 14px 114px;
	letter-spacing: -0.72px;
	font-size: 72px;
	line-height: 80px;
}

.collaboration-card .card-subtitle {
	color: $off-white;
	font-family: Degular;
	font-size: 22px;
	font-style: normal;
	line-height: 32px;
	letter-spacing: 1px;
	padding-top: 23px;
	padding-left: 32%;
}

.collaboration-card .text-link {
	margin-top: 69px;
	margin-left: 32%;
}

.laptop-img {
	width: 45%;
	margin: auto 0 auto 46px;
	padding-bottom: 40px;
}

.collaboration-card .display-bigscreen {
	display: none;
}

.customization-card .container {
	background-color: $off-white;
	border-radius: 0px 50px 50px 0px;
	display: flex;
	flex-direction: row;
	margin: auto;
	padding: 0;
	width: 100%;
}

.customization-card .card-title {
	color: $dark-blue;
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 72px;
	font-weight: 300;
	line-height: 80px;
	padding: 0;
}

.customization-card .card-subtitle {
	color: $dark-blue;
	padding: 37px 0 69px 114px;
}

.customization-card img {
	margin: auto 64px;
	background-color: $turqoise;
	border-radius: 0px 50px 50px 0px;
}

.customization-card a {
	color: $dark-blue;
}

.customization-card .text-link {
	color: $dark-blue;
	margin: 0 0 0 114px;
	border-bottom: 1.5px solid $dark-blue;
	padding: 0 0 3px 0;
	font-size: 20px;
}

.customization-card .text-link a {
	color: $dark-blue;
	font-size: 20px;
	font-weight: 555;
	line-height: 24px;
	letter-spacing: 0.02em;
	text-align: left;
}

.customization-card .padding {
	padding: 70px 80px 85px 80px;
}

.customization-card .full-height {
	display: flex;
	flex-direction: column;
	width: 51%;
}

.laptop-img {
	width: 45%;
}

.collaboration-card .container {
	background-color: $dark-blue;
	border-radius: 50px 0px 0px 50px;
	padding-bottom: 200px;
}

.customization-card .container {
	background-color: $off-white;
	border-radius: 0px 50px 50px 0px;
	display: flex;
	flex-direction: row;
	margin: auto;
	padding: 0;
}

.collaboration-card .left-margin {
	margin-left: 53px;
}

.customization-card.background {
	background-image: linear-gradient(to right, $turqoise 50%, transparent 50%);
	border-radius: 0px 50px 50px 0px;
	display: flex;
	flex-direction: row;
	margin-right: 53px;
	margin-top: calc(719px / -2);
	margin-bottom: calc(719px / -2);
}

.collab-wrapper {
	padding-bottom: calc(719px / 2) !important;
}

.consult-wrapper {
	padding-top: calc(719px / 2);
}

@media (width > 1440px) {
	.laptop-img {
		height: 100%;
	}
}

@media (width < 1330px) {
	.customization-card .padding {
		padding: 50px 60px 65px 60px;
	}

	.customization-card .card-title {
		font-size: 60px;
		line-height: 65px;
		padding: 0;
	}

	.customization-card .card-subtitle {
		padding: 39px 0 40px 104px;
	}
}

@media (width < 1200px) {
	.collaboration-text {
		width: 60%;
	}

	.customization-card .full-height {
		width: 35%;
	}
}

@media (width < 1000px) {
	.collaboration-text {
		width: 100%;
	}
	.collaboration-card .card-title {
		font-size: 60px;
		line-height: 60px;
		padding-left: 60px;
	}

	.customization-card .container {
		flex-direction: column;
		padding: 0;
		margin: 0 53px 0 -53px;
	}

	.customization-card .full-height {
		width: 100%;
		padding: 10px 0;
	}

	.customization-card .padding {
		margin: 0;
	}

	.customization-card .card-title {
		padding: 0;
		color: $dark-blue;
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-style: normal;
		font-weight: 300;
		font-size: 60px;
		line-height: 60px;
	}

	.customization-card .card-subtitle {
		padding: 37px 0 61px 39px;
		color: $dark-blue;
		font-size: 22px;
		line-height: 32px;
		letter-spacing: 0.095px;
	}

	.customization-card .text-link {
		margin: 0 0 0 39px;
	}

	.customization-card a {
		font-size: 18px;
		font-style: normal;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.36px;
	}

	.customization-card img {
		width: 40%;
		margin: auto;
	}
}

@media (width < 900px) {
	.collaboration-card .display-bigscreen {
		display: none;
		flex-direction: column;
	}

	.collaboration-card .display-smallscreen {
		display: block !important;
		overflow: hidden;
		margin: 0 auto;
		width: 80%;
	}

	.collaboration-card .flex-row {
		flex-direction: column;
	}

	.collaboration-card .card-subtitle {
		padding-left: 140px;
		padding-right: 30px;
	}
}

@media (width < 700px) {
	.customization-card .padding {
		padding: 50px 39px 60px 60px;
	}
	.customization-card.background {
		margin-right: 24px;
		width: calc(100% + 24px);
	}
	.collaboration-card .container {
		display: flex;
		gap: 5%;
		flex-direction: column;
		padding-bottom: 92px;
	}

	.collaboration-card .card-title {
		padding: 74px 34px 0 49px;
		margin: 0;
		font-size: 46px;
		line-height: 55px;
		letter-spacing: 0.095px;
	}

	.collaboration-card .card-subtitle {
		padding: 24px 24px 0 100px;
		font-size: 19px;
		font-style: normal;
		line-height: 24px;
		letter-spacing: 1px;
	}

	.collaboration-card .text-link {
		margin-left: 39px;
		padding-bottom: 4px;
	}

	.collaboration-card .text-link a {
		font-size: 18px;
	}

	.collaboration-card .img-wrapper {
		padding-left: 39px;
		margin-bottom: -5px;
		overflow: hidden;
		margin-left: 100px;
	}

	.collaboration-card .display-smallscreen {
		width: 120%;
	}

	.customization-card img {
		width: 80%;
		margin: auto;
	}

	.customization-card .container {
		margin: 0 0 0 -53px;
	}

	.customization-card .text-link {
		margin-top: 49px;
		padding: 0;
	}

	.customization-card .text-link a {
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 1px;
	}

	.customization-card .card-title {
		padding: 0;
		margin: 0;
	}

	.customization-card .card-subtitle {
		padding: 24px 0px 0 39px;
		margin: 0;
		letter-spacing: 1px;
	}
}

@media (width < 500px) {
	.collaboration-card .left-margin {
		margin-left: 0;
	}
	.collaboration-card.background {
		padding-bottom: 0px !important;
		margin-left: 24px;
		margin-bottom: 71px;
	}

	.collab-wrapper {
		margin-left: 0 !important;
	}

	.collaboration-card .display-smallscreen {
		width: 120%;
	}

	.customization-card .container {
		padding: 0;
		width: 100%;
	}

	.collaboration-card .card-title {
		padding: 54px 24px 0 39px;
		margin: 0;
		font-size: 36px;
		line-height: 40px;
		letter-spacing: 0.095px;
	}

	.collaboration-card .card-subtitle {
		padding: 24px 24px 0 39px;
		font-size: 19px;
		font-style: normal;
		line-height: 24px;
		letter-spacing: 0.9px;
	}

	.customization-card .card-title {
		padding: 0;
		margin: 0;
	}

	.customization-card .card-subtitle {
		padding: 24px 0px 0 39px;
		margin: 0;
		letter-spacing: 1px;
	}

	.collaboration-card .img-wrapper {
		margin-left: 0;
	}
}
