@import 'src/styles/palette.module';

.customizations {
	.container {
		margin-left: auto;
		margin: 0 auto -75px auto;
		padding: 130px 80px 214px 133px;
	}
	.customizations-title {
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 72px;
		font-style: normal;
		font-weight: 300;
		line-height: 80px;
		margin-bottom: 56px;
	}
	.customizations-list {
		margin-top: 112px;
		margin-left: auto;
		width: 80%;
		letter-spacing: 1.65px;
	}
	@media (width < 1441px) {
		.container {
			border-radius: 0px 50px 50px 0px;
			background-color: $dark-blue;
			margin: 0px 53px -90px 0;
			padding: 130px 80px 214px 133px;
			color: $off-white;
		}
	}

	@media (width < 1200px) {
		.container {
			padding: 158px 59px 130px 59px;
		}

		.customizations-title {
			margin-bottom: 23px;
			font-size: 52px;
			font-style: normal;
			font-weight: 300;
			line-height: 56px;
		}

		.customizations-list {
			margin-top: 44px;
		}
	}

	@media (width < 700px) {
		.container {
			border-radius: 0px 40px 40px 0px;
			background-color: $dark-blue;
			margin: 0 24px -30px 0;
			padding: 55px 39px 48px 25px;
			color: $off-white;
		}

		.customizations-title {
			margin: 0 0 24px 0;
			font-size: 18px;
			line-height: 24px;
		}

		.customizations-list {
			margin-top: 24px;
			width: 100%;
		}

		.customizations-list svg {
			width: 23px !important;
		}
	}
}
