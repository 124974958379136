@import 'src/styles/palette.module';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smooth: never;
}

@font-face {
	font-family: 'Mohol';
	src:
		local('Mohol-Light'),
		url('../fonts/Mohol-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Degular';
	src:
		local('Degular'),
		url('../fonts/DegularVariable.ttf') format('truetype');
}

html {
	background-color: $off-white;
	font-family: 'Degular';
	color: $dark-blue;
}

body {
	margin: 0;
}

h1,
h2,
h3,
h4 {
	all: unset;
	display: block;
}

.last {
	border-width: 0 !important;
}

.display-smallscreen {
	display: none !important;
}

a {
	text-decoration: none;
}

button {
	cursor: pointer;
}

.flex-center {
	display: flex;
	justify-content: center;
}

.button-dark {
	padding: 20px 30px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 50px;
	background: $dark-blue;
	color: $off-white;
	font-family: Degular;
	font-size: 24px;
	font-style: normal;
	font-weight: 505;
	border: none;
}

.button-dark:hover {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.full-height {
	flex-grow: 1;
	background-color: $turqoise;
	border-radius: 0px 50px 50px 0px;
}

.container {
	max-width: 1440px;
	margin: 0 auto;
	box-sizing: border-box;
}

.arrow-icon {
	margin-bottom: -8px;
}

.card-title {
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-weight: 300;
	font-size: 96px;
	font-style: normal;
	font-weight: 300;
	line-height: 96px;
	letter-spacing: -0.72px;
	max-width: 790px;
}

.additional-title {
	@extend .card-title;
	font-size: 48px;
}

.quality-assurance-title {
	@extend .card-title;
	font-size: 48px;
	max-width: 390px;
}

.card-subtitle {
	font-family: Degular;
	font-size: 22px;
	font-style: normal;
	line-height: 32px;
	letter-spacing: 1px;
	font-weight: 500;
}

.card-subtitle b {
	font-weight: 650;
}

.thin-arrow {
	width: 40px;
	height: 40px;
	margin-bottom: -8px;
}

.button-green {
	padding: 18px 65px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 50px;
	border: 2px solid $dark-blue;
	background-color: $turqoise;
	text-transform: uppercase;
	box-sizing: border-box;
	font-family: Degular;
	font-size: 20px;
	font-weight: 555;
	line-height: 32px;
	letter-spacing: 0.005em;
	text-align: center;
	color: $dark-blue;
}

.button-green:hover {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}

.title-card-title {
	margin-top: 0px;
	margin-left: 133px;
	letter-spacing: -1.44px;
}

.title-arrow {
	padding: 0 5%;
	animation: animate 2s infinite;
	transition: 0.5s ease;
	position: relative;
}

@keyframes animate {
	from {
		opacity: 0;
		top: -20px;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		top: 0;
	}
}

.title-card.background {
	background-color: $turqoise;
	border-radius: 0px 0px 50px 50px;
	color: $dark-blue;
	overflow: hidden;
	@media (width < 700px) {
		border-radius: 0px 0px 40px 40px;
	}
}

.title-card > .container {
	padding: 144px 0 56px 0;
	background-color: $turqoise;
	height: 720px;
	@media (width < 1400px) {
		height: initial;
	}
}

.title-card .card-subtitle {
	margin-left: 133px;
	margin-top: 47px;
	max-width: 604px;
	letter-spacing: 1px;
}

.light-background {
	background-color: $off-white;
}

.dark-card {
	background-image: linear-gradient(to right, $dark-blue 50%, transparent 50%);
}

.dark-card > .container {
	background-color: $dark-blue;
	color: $off-white;
	border-radius: 0px 50px 50px 0px;
}

.how-we-protect .background {
	border-radius: 40px 0px 0px 40px;
	background: $turqoise;
	margin-left: 40%;
	font-size: 42px;
	font-style: normal;
	font-weight: 300;
	line-height: 48px;
	z-index: 100;
	position: relative;
}

.how-we-protect > .container {
	margin-bottom: -128px;
	max-width: 100%;
	padding: 46px 0 65px 40px;
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 42px;
	font-style: normal;
	font-weight: 300;
	line-height: 48px;
	z-index: 100;
	position: relative;
}

.how-we-protect a {
	text-decoration: none;
	color: $dark-blue;
	padding-bottom: 15px;
	border-bottom: 1px solid $dark-blue;
}

.how-we-protect svg {
	margin-left: 15px;
}

.list-item-with-arrow {
	font-size: 32px;
	font-weight: 525;
	line-height: 40px;
	border-bottom: 1px solid $off-white;
	padding: 27px 0;
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.flex {
	display: flex;
}

.gap {
	gap: 24px;
}

.img.rounded {
	border-radius: 40px;
}

.clickable {
	pointer-events: all;
}

@media (width < 1300px) {
	.title-card-title {
		font-size: 60px;
		line-height: 70px;
	}

	.title-card > .container {
		padding-bottom: 48px;
	}
}

@media (width < 1200px) {
	.protect-data > .container {
		font-size: 32px;
		padding: 30px 0 50px 45px;
		margin-bottom: -100px;
	}

	.list-item-with-arrow {
		font-size: 26px;
		line-height: 33px;
	}
}

@media (width < 700px) {
	.display-bigscreen {
		display: none !important;
	}

	.display-smallscreen {
		display: block !important;
	}

	.card-title {
		font-size: 40px;
		line-height: 40px;
		margin-top: 0;
		margin-left: clamp(15px, 10%, 24px);
		padding-right: clamp(15px, 10%, 24px);
	}

	.additional-title {
		@extend .card-title;
		font-size: 24px;
	}

	.quality-assurance-title {
		@extend .card-title;
		font-size: 24px;
	}

	.title-card > .container {
		padding-right: 0;
		padding-top: 120px;
	}

	.title-card .card-subtitle {
		font-family: 'Degular' !important;
		line-height: 24px;
		letter-spacing: 0.5px;
		font-size: 19px;
		margin-left: clamp(15px, 10%, 24px);
		padding-right: clamp(15px, 10%, 24px);
		padding-bottom: 40px !important;
	}

	.button-dark.display-bigscreen {
		position: relative;
		margin-left: auto;
		margin-right: 24px;
		padding: 15px 45px;
		text-decoration: none;
	}

	button.display-smallscreen {
		margin-left: auto;
		margin-right: 24px;
		padding: 15px 45px;
		text-decoration: none;
		letter-spacing: 2px;
	}

	.protect-data.wrapper {
		margin-left: 18px;
	}

	.protect-data .background {
		border-radius: 30px 0px 0px 30px;
		width: 100%;
	}

	.protect-data > .container {
		padding: 30px 20px 30px 35px;
		margin-bottom: -48px;
		font-size: 22px;
		line-height: 24px;
	}

	.protect-data a {
		display: inline-block;
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		padding-bottom: 10px;
	}

	.protect-data svg {
		width: 27px !important;
		height: 35px !important;
		margin-left: 3px;
	}

	.list-item-with-arrow {
		font-size: 22px;
		font-weight: 525;
		line-height: 24px;
		border-bottom: 1px solid $off-white;
		padding: 21px 0;
	}

	.img.rounded {
		border-radius: 30px;
	}
}

@media (width < 400px) {
	.title-card .card-subtitle {
		letter-spacing: 0.9px;
	}
}

@media (width < 375px) {
	.title-card .card-subtitle {
		margin-top: 10em !important;
	}
}

* {
	scrollbar-color: $turqoise $dark-blue;
	scrollbar-width: thin;
}

* > b {
	font-weight: 600;
}
