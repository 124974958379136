$off-white: #ecfffa;
$turqoise: #00c495;
$dark-blue: #0f1936;
$white: #ffffff;
$grey: #e4e9e4;
$purle: #6e7cff;
$yellow-green: #cdf98c;
$red: #ff4747;

:export {
	offWhite: $off-white;
	turqoise: $turqoise;
	darkBlue: $dark-blue;
	white: $white;
	grey: $grey;
	purle: $purle;
	yellowGreen: $yellow-green;
	red: $red;
}
