@import 'src/styles/palette.module';

.case-studies {
	.case-studies-title {
		margin: 0 auto 72px;
		font-family: Mohol;
		font-size: 54px;
		font-weight: 300;
		line-height: 64px;
		letter-spacing: -0.005em;
		text-align: center;
		color: $dark-blue;
		font-feature-settings:
			'dlig' on,
			'salt' on,
			'ss03' on,
			'ss04' on;
	}
	.case-studies-swiper {
		.swiper-pagination-fraction {
			position: static;
			margin-top: 40px;
			font-family: Degular;
			font-size: 26px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.0025em;
			text-align: center;
			color: $dark-blue;
		}
		.swiper-button-prev {
			background-image: url(../resources/img/caseStudiesArrowPrev.svg) !important;
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: center;
			left: 53px;
			width: 56px;
			height: 56px;
			&::after {
				content: none;
			}
		}

		.swiper-button-next {
			background-image: url(../resources/img/caseStudiesArrowNext.svg) !important;
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: center;
			right: 53px;
			width: 56px;
			height: 56px;
			&::after {
				content: none;
			}
		}

		.industry {
			text-transform: uppercase;
			font-size: 25px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
			margin-bottom: 10px;
		}
	}
	.case-studies-item {
		max-width: 1106px;
		min-height: 552px;
		width: calc(100% - 167px * 2);
		background: #ffffff;
		border-radius: 50px;
		box-shadow: 0px 15px 20px 0px #00c4951a;
		margin: 0 auto;
		overflow: hidden;
		display: flex;
		.case-studies-item-photo {
			max-height: 580px;
			border-radius: 50px;
			overflow: hidden;
			& > img {
				height: 100%;
				display: block;
			}
		}
		.case-studies-item-block {
			margin: 50px 57px 39px 70px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			.case-studies-item-logo {
				height: 56px;
				width: auto;
				margin-bottom: 25px;
				& > img {
					height: 100%;
				}
			}
			.case-studies-item-title {
				color: $dark-blue;
				font-family: Mohol;
				font-size: 42px;
				font-weight: 300;
				line-height: 48px;
				text-align: left;
				margin-bottom: 30px;
				max-width: 440px;
				font-feature-settings:
					'dlig' on,
					'salt' on,
					'ss03' on,
					'ss04' on;
			}
			.case-studies-item-description {
				color: $dark-blue;
				font-family: Degular;
				font-size: 22px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0.0025em;
				text-align: left;
				overflow: hidden;
				display: -webkit-box;
				line-clamp: 5;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				box-sizing: border-box;
				max-width: 440px;
			}
			.case-studies-item-link {
				align-self: flex-end;
				color: $dark-blue;
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				margin-top: 50px;
				text-transform: uppercase;
				border-bottom: 1.5px solid $dark-blue;
			}
		}
	}
	@media (width < 1200px) {
		.case-studies-item {
			flex-direction: column;
			max-width: 600px;
			.case-studies-item-photo {
				max-width: 100%;
				max-height: 300px;
				& > img {
					height: auto;
					width: 100%;
				}
			}
			.case-studies-item-block {
				box-sizing: border-box;
				margin: 50px;
				width: calc(100% - 100px);
			}
		}
	}
	@media (width < 900px) {
		.case-studies-title {
			font-size: 36px;
			line-height: 40px;
			margin: 0 24px 53px;
			text-align: left;
		}
		.case-studies-swiper {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin: 0 24px;
			.case-studies-item {
				width: 100%;
				margin: 0 0 32px;
				border-radius: 20px;
				max-width: 400px;
				.case-studies-item-photo {
					border-radius: 20px;
				}
				.case-studies-item-block {
					margin: 30px;
					width: calc(100% - 60px);
					& > div {
						width: 100%;
						& > .case-studies-item-logo {
							margin-bottom: 5px;
							height: 40px;
						}
						& > .case-studies-item-title {
							font-size: 26px;
							line-height: 32px;
							margin-bottom: 21px;
						}
						& > .case-studies-item-description {
							font-size: 19px;
							line-height: 24px;
						}
					}
					.case-studies-item-link {
						font-size: 18px;
						line-height: 24px;
						text-align: right;
					}
				}
			}
		}
	}
}
