@import 'src/styles/palette.module';

.security-block {
	.container {
		padding: 121px 133px 161px;
		display: flex;
		flex-direction: column;
	}

	.security-text {
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 48px;
		font-style: normal;
		font-weight: 300;
		line-height: 64px;
		width: 85%;
		max-width: 912px;
		& > span {
			border-bottom: 2px solid $dark-blue;
		}
	}

	.security-subtext {
		margin-left: auto;
		font-family: Degular;
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px;
		margin-top: 158px;
		letter-spacing: 0.7px;
		max-width: 515px;
		& > b {
			font-weight: 650;
		}
	}

	@media (width < 1000px) {
		.container {
			padding: 10%;
			display: flex;
			flex-direction: column;
		}

		.security-text {
			font-size: 35px;
			line-height: 50px;
		}

		.security-subtext {
			margin-top: 80px;
			width: 60%;
			margin-bottom: 20px;
		}
	}

	@media (width < 700px) {
		.security-text {
			font-size: 26px;
			line-height: 32px;
			width: 100%;
		}

		.security-subtext {
			font-size: 19px;
			font-weight: 500;
			letter-spacing: 0.52px;
			line-height: 24px;
			width: 83%;
			margin-top: 85px;
			margin-left: auto;
		}
	}

	@media (width < 400px) {
		.container {
			flex-direction: column;
			padding: 52px 0 81px;
		}

		.security-text {
			padding: 0 28px 0 24px;
			width: auto;
			font-weight: 500;
		}

		.security-subtext {
			margin: 70px 24px 0 20%;
			font-weight: 550;
			width: auto;
		}
	}
}
