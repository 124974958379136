@import 'src/styles/palette.module';

.about-us {
	.our-team {
		background: $turqoise;
		& > .dark-card {
			background: $dark-blue;
			border-radius: 50px 50px 0 0;
			& > .container {
				padding: 131px 133px 126px;
				.our-team-title {
					color: $off-white;
					font-family: Mohol;
					font-size: 72px;
					font-weight: 300;
					line-height: 80px;
					letter-spacing: -0.01em;
					text-align: center;
					margin: 0 auto;
				}
				.our-team-subtitle {
					color: $off-white;
					font-family: Degular;
					font-size: 22px;
					font-weight: 500;
					line-height: 32px;
					letter-spacing: 0.0025em;
					text-align: center;
					margin: 58px auto 75px;
					max-width: 877px;
					& > a {
						color: $off-white;
						text-decoration: underline;
					}
				}
				.our-team-members {
					display: flex;
					gap: 47px 64px;
					flex-wrap: wrap;
					.our-team-card {
						width: calc(100% / 3 - 43px);
						.our-team-card-photo {
							width: 100%;
							border-radius: 20px;
							overflow: hidden;
							position: relative;
							& > img {
								width: 100%;
								height: auto;
								display: block;
							}
							& > .our-team-card-link {
								position: absolute;
								z-index: 2;
								bottom: 20px;
								right: 20px;
							}
						}
						.our-team-card-name {
							color: $off-white;
							font-family: Degular;
							font-size: 20px;
							font-weight: 555;
							line-height: 24px;
							letter-spacing: 0.02em;
							text-align: left;
							margin: 22px 0 7px;
							text-transform: uppercase;
						}
						.our-team-card-title {
							color: $off-white;
							font-family: Degular;
							font-size: 20px;
							font-weight: 529;
							line-height: 26px;
							letter-spacing: 0.0075em;
							text-align: left;
						}
					}
				}
				.our-team-joining-block {
					padding: 172px 0 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					.our-team-joining-title {
						margin: 0 auto 48px;
						text-align: center;
						color: $off-white;
						font-family: Mohol;
						font-size: 42px;
						font-weight: 300;
						line-height: 48px;
						text-align: center;
						font-feature-settings:
							'dlig' on,
							'salt' on,
							'ss03' on,
							'ss04' on;
					}
					& > a {
						& > .button-green {
							padding: 20px 70px;
						}
					}
				}
			}
		}
		@media (width < 1200px) {
			& > .dark-card {
				& > .container {
					.our-team-members {
						.our-team-card {
							width: calc(100% / 2 - 32px);
							.our-team-card-link {
								& svg {
									width: 40px;
									height: 40px;
								}
							}
						}
					}
				}
			}
		}
		@media (width < 1000px) {
			& > .dark-card {
				& > .container {
					padding: 131px 10% 126px;
					.our-team-members {
						.our-team-card {
							width: calc(100% / 2 - 32px);
							.our-team-card-link {
								& svg {
									width: 40px;
									height: 40px;
								}
							}
						}
					}
				}
			}
		}
		@media (width < 900px) {
			& > .dark-card {
				border-radius: 40px 40px 0 0;
				& > .container {
					padding: 53px 24px;
					.our-team-title {
						font-size: 36px;
						line-height: 40px;
					}
					.our-team-subtitle {
						font-size: 19px;
						line-height: 24px;
						margin: 41px auto 50px;
					}
					.our-team-members {
						gap: 40px;
						.our-team-card {
							width: calc(50% - 17px);
							.our-team-card-link {
								& svg {
									width: 56px;
									height: 56px;
								}
							}
							.our-team-card-name {
								margin: 8px 0 3px;
								font-size: 18px;
								line-height: 24px;
							}
							.our-team-card-title {
								font-size: 17px;
								line-height: 22px;
							}
						}
					}
					.our-team-joining-block {
						padding: 103px 0 100px;
						.our-team-joining-title {
							font-size: 26px;
							line-height: 32px;
							margin-bottom: 30px;
							max-width: 327px;
							& > br {
								display: none;
							}
						}
						& > a {
							& > .button-green {
								font-size: 18px;
								line-height: 24px;
							}
						}
					}
				}
			}
		}
		@media (width < 700px) {
			& > .dark-card {
				border-radius: 40px 40px 0 0;
				& > .container {
					padding: 53px 24px;
					.our-team-title {
						font-size: 36px;
						line-height: 40px;
					}
					.our-team-subtitle {
						font-size: 19px;
						line-height: 24px;
						margin: 41px auto 50px;
					}
					.our-team-members {
						flex-direction: column;
						gap: 40px;
						.our-team-card {
							width: 100%;
							.our-team-card-link {
								& svg {
									width: 56px;
									height: 56px;
								}
							}
							.our-team-card-name {
								margin: 8px 0 3px;
								font-size: 18px;
								line-height: 24px;
							}
							.our-team-card-title {
								font-size: 17px;
								line-height: 22px;
							}
						}
					}
					.our-team-joining-block {
						padding: 103px 0 100px;
						.our-team-joining-title {
							font-size: 26px;
							line-height: 32px;
							margin-bottom: 30px;
							& > br {
								display: none;
							}
						}
						& > a {
							& > .button-green {
								font-size: 18px;
								line-height: 24px;
							}
						}
					}
				}
			}
		}
	}
}
