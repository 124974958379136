@import 'src/styles/palette.module';

.industry-overview {
	.title-card {
		& > .container {
			padding-top: 112px;
		}
	}
	.flex-center {
		align-items: center;
	}
	.flex-center.flex-column {
		margin: 0 133px;
	}
	.card-label {
		text-transform: uppercase;
		padding: 10px;
		border-radius: 10px;
		background: $off-white;
		color: $dark-blue;
		font-family: Degular;
		font-size: 20px;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.02em;
		text-align: center;
		margin-bottom: 22px;
	}
	.title-card-title {
		margin-left: 0;
		max-width: 100%;
		font-size: 72px;
		font-weight: 300;
		line-height: 80px;
		letter-spacing: -0.01em;
		text-align: center;
	}
	.card-text {
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.0025em;
		text-align: center;
		padding: 38px 0 250px;
		max-width: 650px;
	}
	.img-wrapper {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		& > img {
			width: 100%;
			max-width: 878px;
			margin: -210px auto 0;
		}
	}
	@media (width < 1000px) {
		.flex-center.flex-column {
			margin: 0 10%;
		}
	}
	@media (width < 900px) {
		.flex-center.flex-column {
			margin: 0 24px;
		}
		.title-card-title {
			font-size: 32px;
			line-height: 46px;
			padding-right: 0;
		}
		.card-text {
			font-size: 22px;
			line-height: 32px;
			padding: 24px 0 31px;
		}
		.img-wrapper {
			& > img {
				margin: -170px auto 0;
			}
		}
		.title-card {
			.title-arrow {
				margin-bottom: 150px;
			}
		}
		.card-label {
			padding: 0 7px;
			font-size: 18px;
			font-weight: 555;
			line-height: 24px;
		}
	}
}
