@import 'src/styles/palette.module';

.number-accordion {
	background: $turqoise;
	border-radius: 20px;
	padding: 35px;
	margin-bottom: 16px;
	transition: all 0.2s ease-in-out;
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	&.active {
		width: 100% !important;
		.number-accordion-button {
			width: 70px;
			height: 70px;
			top: 30px;
			right: 30px;
		}
	}
	.number-accordion-button {
		position: absolute;
		top: 18px;
		right: 18px;
		width: 53px;
		height: 53px;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		z-index: 2;
		box-sizing: border-box;
		& > svg {
			width: 100%;
			height: 100%;
		}
		&.active {
			transform: rotate(45deg);
		}
	}
	.number-accordion-header {
		display: flex;
		align-items: flex-start;
		position: relative;
		width: 100%;
	}
	&.active {
		padding: 100px 30px 66px 133px;
		border-radius: 0 50px 50px 0;
		.number-accordion-number {
			font-size: 176px;
			line-height: 96px;
		}
	}
	.number-accordion-number {
		font-family: Mohol;
		font-size: 106px;
		font-weight: 300;
		line-height: 60px;
		height: 74px;
		letter-spacing: -0.015em;
		text-align: left;
		margin-right: 25px;
		color: $dark-blue;
		transition: all 0.2s ease-in-out;
		& > span {
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
		}
	}
	.number-accordion-title {
		font-family: Degular;
		font-size: 16px;
		font-weight: 555;
		line-height: 24px;
		letter-spacing: 0.02em;
		text-align: left;
		color: $dark-blue;
		text-transform: uppercase;
		max-width: 229px;
		width: 100%;
		margin-right: 60px;
	}
	.number-accordion-text {
		font-family: Mohol;
		font-size: 0px;
		font-weight: 300;
		text-align: left;
		margin-top: 0px;
		color: $dark-blue;
		line-height: 0px;
		display: block;
		opacity: 0;
		transition:
			opacity 0.2s ease-in-out,
			margin-top 0.2s ease-in-out,
			font-size 0.15s ease-in-out,
			line-height 0.15s ease-in-out;
		& > p {
			& > span {
				border-bottom: 2px solid $dark-blue;
			}
		}
		&.active {
			margin-top: 135px;
			font-size: 48px;
			line-height: 64px;
			opacity: 1;
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
		}
	}
	@media (width < 700px) {
		border-radius: 0 20px 20px 0;
		padding: 25px 16px 25px 25px;
		&.active {
			width: 100vw !important;
			border-radius: 0 40px 40px 0;
			padding: 24px 24px 54px 24px;
			.number-accordion-number {
				font-size: 140px;
				line-height: 96px;
				margin-top: 46px;
			}
		}
		.number-accordion-button {
			width: 40px;
			height: 40px;
			& > svg {
				width: 100%;
				height: 100%;
			}
			&.active {
				position: relative;
				align-self: flex-end;
				top: auto;
				right: auto;
				width: 56px;
				height: 56px;
			}
		}
		.number-accordion-header {
			flex-direction: column;
		}
		.number-accordion-number {
			font-size: 70px;
			line-height: 70px;
			margin: 0 0 15px;
			height: unset;
		}
		.number-accordion-title {
			font-size: 16px;
			line-height: 24px;
			margin: 23px 0 0;
		}
		.number-accordion-text {
			font-size: 0;
			line-height: 0;

			&.active {
				font-size: 26px;
				line-height: 32px;
			}
		}
	}
}
