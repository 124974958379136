@import 'src/styles/palette.module';

.about-us {
	.why-us {
		background: $turqoise;
		& > .container {
			padding: 106px 133px 176px;
		}
		.why-us-title {
			color: $dark-blue;
			font-family: Mohol;
			font-size: 72px;
			font-weight: 300;
			line-height: 80px;
			letter-spacing: -0.01em;
			text-align: left;
		}
		.why-us-content {
			display: flex;
			flex-wrap: wrap;
			margin-top: 70px;
			gap: 16px;
			.why-us-card {
				box-sizing: border-box;
				padding: 32px;
				border-radius: 40px;
				background: $off-white;
				.why-us-card-label {
					text-transform: uppercase;
					padding: 8px 20px;
					border-radius: 100px;
					background: $turqoise;
					color: $dark-blue;
					font-family: Degular;
					font-size: 20px;
					font-weight: 555;
					line-height: 24px;
					letter-spacing: 0.02em;
					text-align: left;
					margin-bottom: 58px;
					width: fit-content;
				}
				.why-us-card-description {
					color: $dark-blue;
					font-family: Degular;
					font-size: 22px;
					font-weight: 500;
					line-height: 32px;
					letter-spacing: 0.0025em;
					text-align: left;
					max-width: 460px;
					& b {
						font-weight: 650;
					}
				}
				&:first-child {
					width: calc(46% - 8px);
				}
				&:nth-child(2) {
					width: calc(54% - 8px);
					background: $dark-blue;
					.why-us-card-description {
						color: $off-white;
					}
				}
				&:nth-child(3) {
					width: calc(54% - 8px);
					background: $dark-blue;
					.why-us-card-description {
						color: $off-white;
					}
				}
				&:nth-child(4) {
					width: calc(46% - 8px);
				}
			}
		}
	}
	@media (width < 1000px) {
		.why-us {
			& > .container {
				padding: 106px 10% 176px;
			}
		}
	}
	@media (width < 900px) {
		.why-us {
			overflow: hidden;
			& > .container {
				padding: 86px 0 104px;
			}
			.why-us-title {
				font-size: 36px;
				line-height: 40px;
				margin-left: 24px;
			}
			.why-us-content {
				margin-top: 42px;
				flex-direction: column;
				.why-us-card {
					padding: 26px;
					border-radius: 30px;
					.why-us-card-label {
						font-size: 18px;
						margin-bottom: 38px;
					}
					.why-us-card-description {
						font-size: 19px;
						line-height: 24px;
					}
					&:first-child {
						max-width: 327px;
						margin: 0 24px;
						width: 100%;
					}
					&:nth-child(2) {
						max-width: 310px;
						margin: 0 0 0 65px;
						border-radius: 30px 0 0 30px;
						align-self: flex-end;
						width: 100%;
					}
					&:nth-child(3) {
						max-width: 312px;
						margin: 0 65px 0 0;
						border-radius: 0 30px 30px 0;
						width: 100%;
						background: $off-white;
						.why-us-card-description {
							color: $dark-blue;
						}
					}
					&:nth-child(4) {
						max-width: 327px;
						margin: 0 24px;
						align-self: center;
						width: 100%;
						background: $dark-blue;
						.why-us-card-description {
							color: $off-white;
						}
					}
				}
			}
		}
	}
}
