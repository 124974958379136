@import 'src/styles/palette.module';

.privacy-enhancing-technologies {
	.flex-center {
		align-items: center;
	}
	.flex-center.flex-column {
		margin: 0 133px;
	}
	.title-card-title {
		margin-left: 0;
		max-width: 100%;
		font-size: 72px;
		font-weight: 300;
		line-height: 80px;
		letter-spacing: -0.01em;
		text-align: center;
	}
	.card-text {
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.0025em;
		text-align: center;
		padding: 38px 0 260px;
	}
	.img-wrapper {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		& > img {
			width: 100%;
			max-width: 878px;
			margin: -250px auto 0;
		}
	}
	@media (width < 1000px) {
		.flex-center.flex-column {
			margin: 0 10%;
		}
	}
	@media (width < 700px) {
		.flex-center.flex-column {
			margin: 0 24px;
		}
		.title-card-title {
			font-size: 36px;
			line-height: 40px;
		}
		.card-text {
			font-size: 19px;
			line-height: 24px;
			padding: 25px 0 50px;
		}
		.img-wrapper {
			& > img {
				margin: -70px auto 0;
			}
		}
		.title-card {
			.title-arrow {
				// margin-bottom: 130px;
				display: none;
			}
		}
	}
}
