@import 'src/styles/palette.module';

.demo-route {
	.container {
		background-color: transparent;
		.flex-center {
			margin-bottom: 180px;
		}
	}

	.title-card-title {
		margin: auto;
		font-size: 72px;
		text-align: center;
	}

	.button-green {
		margin: 46px auto 0;
		text-transform: none !important;
		color: $dark-blue !important;
	}

	@media (max-width: 800px) {
		.container {
			background-color: transparent;
			.flex-center {
				margin-bottom: 230px;
			}
		}
		.title-card-title {
			font-size: 48px;
			margin: 0 50px;
			line-height: 56px;
		}

		.button-green {
			padding: 20px 50px;
		}
	}

	@media (max-width: 500px) {
		.container {
			padding-top: 117px;
		}
		.background {
			border-radius: 0px 0px 40px 40px;
		}

		.title-card-title {
			font-variant-numeric: ordinal;
			margin: 0;
			font-feature-settings:
				'dlig' on,
				'ss03' on,
				'salt' on,
				'ss04' on;
			font-size: 36px;
			font-weight: 300;
			line-height: 40px; /* 111.111% */
			letter-spacing: 0.09px;
			padding: 0 24px;
		}

		.button-green {
			font-size: 18px;
			line-height: 24px;
		}
	}
}
