@import 'src/styles/palette.module';

.confirm-subscription {
    background: $turqoise;
    .title-card {
        margin-bottom: -50px;
    }
    .container {
        margin-bottom: -30px;
    }
    .title-card-title {
        margin-left: 0;
        max-width: 100%;
        font-size: 72px;
        font-weight: 300;
        line-height: 80px;
        letter-spacing: -0.01em;
        text-align: center;
    }
    .card-title {
        padding: 0;
    }
    .sub-text {
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 123.077% */
        letter-spacing: 0.065px;
        text-align: center;
        margin-top: 24px;
    }
    @media (max-width: 1400px) {
        .title-card .sub-text {
            height: 300px;
        }
    }
    
    @media (max-width: 700px) {
        .title-card-title {
            font-size: 36px;
            line-height: 40px;
        }
        .title-card .sub-text {
            font-size: 22px;
            line-height: 32px;
            margin-top: 16px;
            height: 200px;
        }
    }
}


