@import 'src/styles/palette.module';

.text-link {
	margin: 68px 0 144px calc(114px + 10%);
	text-decoration: none;
	color: $off-white;
	width: fit-content;
	padding: 0 0 10px 0;
	border-bottom: 1px solid $off-white;
	display: flex;
	gap: 10px;
}

.text-link a {
	text-decoration: none;
	color: $off-white;
	font-size: 20px;
}

@media (width < 1200px) {
	.partners-title {
		padding: 0 100px 45px;
	}
}

@media (width < 700px) {
	.text-link {
		margin: 61px 39px;
		text-decoration: none;
		color: $off-white;
		width: fit-content;
		padding: 0 0 10px 0;
		border-bottom: 1.5px solid $off-white;
		display: flex;
		gap: 10px;
	}
}

@media (width < 375px) {
	// .card-title {
	//     font-size: 2erm !important;
	// }

	.card-subtitle {
		font-size: 1.5rem !important;
	}
}
