@import 'src/styles/palette.module';

.industry-overview {
	.industry-outcomes {
		margin: 0 0 136px 53px;
		background-color: $dark-blue;
		border-radius: 50px 0 0 50px;
		padding: 112px 53px 131px;
		.industry-block {
			border-radius: 0 50px 50px 0;
			background: $off-white;
			display: flex;
			align-items: stretch;
			margin: 0 0 0 -134px;
			// width: calc(100% - 53px);
			@media (width > 1800px) {
				max-width: 70vw;
			}
			.img-wrapper {
				background: $turqoise;
				border-radius: 0 50px 50px 0;
				padding: 40px;
				img {
					max-width: 456px;
					margin: 0 auto;
					@media (width > 1800px) {
						margin: 0 0 0 auto;
					}
				}
			}
			.industry-description {
				margin: 50px 50px 50px 80px;
				max-width: 450px;
				font-family: Degular;
				font-size: 22px;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0.0025em;
				text-align: left;
				color: $dark-blue;
				display: flex;
				align-items: center;
				& b {
					font-weight: 650;
				}
			}
		}
		.industry-outcomes-block {
			max-width: 946px;
			margin: 126px 133px 0 auto;
			.industry-outcomes-subtitle {
				color: $off-white;
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				text-transform: uppercase;
			}
			.industry-outcomes-list {
				margin-top: 80px;
				.industry-outcomes-list-item {
					color: $off-white;
					font-family: Degular;
					font-size: 32px;
					font-weight: 525;
					line-height: 40px;
					letter-spacing: 0.01em;
					text-align: left;
					border-bottom: 1px solid $off-white;
					padding-bottom: 40px;
					margin-bottom: 40px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					&:last-child {
						border-bottom: none;
						padding-bottom: 0;
						margin-bottom: 0;
					}
					& > div {
						margin-right: 20px;
					}
				}
			}
		}
	}
	.you-might-be-interested {
		margin-bottom: 180px;
		@media (width < 900px) {
			margin-bottom: 90px;
		}
	}
	.case-studies {
		margin-top: 300px;
		@media (width < 900px) {
			margin-top: 100px;
		}
	}
	@media (width < 1000px) {
		.industry-outcomes {
			.industry-outcomes-block {
				margin: 126px 0 0 auto;
			}
		}
	}
	@media (width < 900px) {
		.industry-outcomes {
			margin: 0 0 87px 24px;
			border-radius: 40px 0 0 40px;
			padding: 64px 24px;
			.industry-block {
				flex-direction: column;
				align-items: center;
				border-radius: 0 40px 40px 0;
				margin: 0 0 0 -48px;
				.img-wrapper {
					border-radius: 0 40px 40px 0;
					img {
						max-width: 256px;
					}
				}
				.industry-description {
					margin: 30px 24px 65px;
					font-size: 19px;
					line-height: 24px;
				}
			}
			.industry-outcomes-block {
				margin: 63px 0 0 39px;
				.industry-outcomes-subtitle {
					font-size: 18px;
					line-height: 24px;
				}
				.industry-outcomes-list {
					margin-top: 44px;
					.industry-outcomes-list-item {
						font-size: 22px;
						line-height: 24px;
						padding-bottom: 26px;
						margin-bottom: 26px;
						align-items: flex-start;
					}
				}
			}
		}
	}
}
