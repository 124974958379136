@import 'src/styles/palette.module';

.specific-use-case {
	.outcomes {
		padding: 94px 227px 122px 133px;
		// max-width: 1300px;
		width: calc(100% - 54px);
		margin-right: 54px;
		border-radius: 0 50px 50px 0;
		box-sizing: border-box;
		background: $dark-blue;
		@media (width > 1800px) {
			padding: 94px 227px 122px calc((100vw - 1440px) / 2 + 133px);
			max-width: 70vw;
		}
		.outcomes-subtitle {
			color: $off-white;
			font-family: Degular;
			font-size: 20px;
			font-weight: 555;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-align: left;
			margin-bottom: 56px;
			text-transform: uppercase;
		}
		.outcomes-title {
			color: $off-white;
			font-family: Mohol;
			font-size: 42px;
			font-weight: 300;
			line-height: 48px;
			text-align: left;
			max-width: 1026px;
			width: 100%;
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
		}
	}
	@media (width < 1000px) {
		.outcomes {
			padding: 94px 10% 122px 10%;
			margin-right: 24px;
			width: calc(100% - 24px);
		}
	}
	@media (width < 900px) {
		.outcomes {
			padding: 55px 24px 75px 24px;
			margin-right: 24px;
			width: calc(100% - 24px);
			.outcomes-subtitle {
				margin-bottom: 22px;
				font-size: 18px;
				line-height: 24px;
			}
			.outcomes-title {
				font-size: 26px;
				line-height: 32px;
			}
		}
	}
}
