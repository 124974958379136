@import 'src/styles/palette.module';

.coming-soon-route {
	background-color: $dark-blue;
	padding-top: 164px;
}

.coming-soon {
	padding: 0 0 100px 130px;
	margin-bottom: -50px;
	color: $off-white;
	display: flex;
	flex-direction: row;
	@media (width > 1800px) {
		padding: 0 0 100px calc((100vw - 1440px) / 2 + 133px);
	}
}

.coming-soon-route .contact-button {
	background-color: $turqoise;
	color: $dark-blue !important;
}

.coming-soon .card-title {
	margin-top: 10px;
	margin-right: 10px;
}

.coming-soon .text {
	background-color: $turqoise;
	border-radius: 50px 0px 0px 50px;
	padding: 75px 113px 40px 84px;
	color: $dark-blue;
	box-sizing: border-box;
	@media (width > 1800px) {
		padding: 75px calc((100vw - 1440px) / 2 + 133px) 40px 84px;
		max-width: 60vw;
		margin-left: auto;
	}
}

.coming-soon .main-text {
	font-family: Mohol;
	font-size: 48px;
	font-weight: 250;
	line-height: 64px;
	font-feature-settings:
		'dlig' on,
		'salt' on,
		'ss03' on,
		'ss04' on;
}

.coming-soon .card-subtitle {
	margin: 25px 0 0 148px;
	max-width: 536px;
}

.coming-soon .card-subtitle a {
	color: $dark-blue;
	text-decoration: none;
	font-weight: 600;
}

.linkedin-btn {
	color: $dark-blue;
	padding: 20px 60px;
	border-radius: 50px;
	border: 2px solid var(--Primary-Dark-blue, #0f1936);
	font-size: 20px;
	font-style: normal;
	font-weight: 455;
	line-height: 32px; /* 160% */
	letter-spacing: 0.1px;
	text-transform: uppercase;
	text-decoration: none;
	display: block;
	width: fit-content;
	margin-top: 50px;
}

@media (width > 1440px) {
	.coming-soon {
		padding-top: 0;
		margin-bottom: -50px;
	}

	.coming-soon-route {
		margin-bottom: -53px;
	}
}

@media (width < 1200px) {
	.coming-soon .sub-text {
		margin-left: 30px;
	}
}

@media (width < 1000px) {
	.coming-soon {
		padding: 10px 0 30px 24px;
		flex-direction: column;
	}

	.coming-soon .card-title {
		margin: 0 0 37px 0;
	}
}

@media (width < 700px) {
	.coming-soon {
		padding: 10px 0 30px 24px;
		margin-bottom: -50px;
		flex-direction: column;
	}

	.coming-soon .card-title {
		margin: 0 0 37px 0;
	}

	.coming-soon .text {
		border-radius: 40px 0px 0px 40px;
		padding: 36px 20px 55px 39px;
	}

	.coming-soon .card-subtitle {
		margin: 31px 0 0 32px;
		line-height: 24px;
		letter-spacing: 0.9px;
		font-size: 19px;
	}

	.coming-soon .main-text {
		font-size: 26px;
		line-height: 32px;
	}

	.linkedin-btn {
		margin-top: 37px;
		padding: 15px 73px;
	}
}
