@import 'src/styles/palette.module';

.case-studies {
	margin: 160px 0 133px;
	@media (width < 900px) {
		margin: 75px 0 78px;
	}
}

.specific-use-case-get-in-touch {
	margin: 155px 133px 133px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	.specific-use-case-get-in-touch-title {
		margin-bottom: 48px;
		color: $dark-blue;
		font-family: Mohol;
		font-size: 42px;
		font-weight: 300;
		line-height: 48px;
		text-align: center;
		max-width: 878px;
		font-feature-settings:
			'dlig' on,
			'salt' on,
			'ss03' on,
			'ss04' on;
	}
	.button-dark {
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 555;
		line-height: 32px;
		letter-spacing: 0.005em;
		padding: 20px 60px;
	}
	@media (width < 1000px) {
		margin: 155px 10% 133px;
	}
	@media (width < 900px) {
		margin: 70px 24px 88px;
		.specific-use-case-get-in-touch-title {
			font-size: 26px;
			line-height: 32px;
			margin-bottom: 30px;
		}
		.button-dark {
			padding: 24px 65px;
			font-size: 18px;
			line-height: 24px;
		}
	}
}
