@import 'src/styles/palette.module';

.collaboration-platform {
	.try-demo {
		text-transform: lowercase;
	}
	.container {
		background: none;
	}

	.collab-flex-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.collab-flex-row:last-child {
		margin-right: auto;
	}

	.try-demo {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		margin-top: 75px;
	}

	.flex-column {
		justify-content: space-between;
	}

	.card-subtitle {
		margin-top: 37px;
	}

	img {
		max-width: 100%;
		border-radius: 20px;
	}

	.try-demo {
		margin-right: 53px;
	}

	.title-wrapper {
		padding-right: 30px;
	}

	@media (width > 1300px) {
		.img-wrapper {
			margin-right: 53px;
			max-width: 680px;
		}

		.title-card-title {
			max-width: 600px;
			font-size: 72px;
			font-style: normal;
			font-weight: 300;
			line-height: 80px; /* 111.111% */
			letter-spacing: -0.72px;
		}

		.collab-flex-row {
			justify-content: space-between;
		}
	}

	@media (width < 1300px) {
		.title-wrapper {
			padding-right: 0;
		}

		.flex-column {
			overflow: hidden;
		}

		.flex-column.display-bigscreen {
			padding-left: 20px;
		}

		.img-wrapper {
			margin: 27px 0 37px auto;
			object-fit: cover;
		}

		.img-wrapper img {
			overflow: hidden;
			max-width: 120%;
		}

		.try-demo {
			margin-top: 20px;
		}

		.title-card-title {
			margin-left: 80px !important;
		}
		.card-subtitle {
			margin-left: 80px;
		}
	}

	@media (width < 900px) {
		.collab-flex-row {
			flex-direction: column;
			padding: 0;
		}

		.img-wrapper {
			overflow: hidden;
			width: 70%;
			margin: 32px 0 37px auto;
		}

		.try-demo {
			margin-top: 0;
		}
	}

	@media (width < 700px) {
		.title-card-title {
			font-feature-settings:
				'dlig' on,
				'ss03' on,
				'salt' on,
				'ss04' on;
			/* H2/Mobile */
			font-family: 'Mohol';
			font-size: 36px;
			font-style: normal;
			font-weight: 300;
			line-height: 40px; /* 111.111% */
			letter-spacing: 0.09px;
		}
		.card-subtitle {
			margin-top: 30px !important;
			font-size: 19px !important;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0.095px;
		}

		.try-demo {
			margin-top: 40px;
		}

		img {
			border-radius: 10px;
		}
	}

	@media (width < 500px) {
		.title-card-title,
		.card-subtitle {
			margin-left: 24px !important;
			margin-top: 0 !important;
			padding-bottom: 26px !important;
		}
		.title-card {
			.container {
				.button-dark {
					font-size: 18px;
					font-weight: 555;
					line-height: 24px;
					letter-spacing: 0.02em;
					padding: 20px 60px;
				}
			}
		}
	}
}
