@import 'src/styles/palette.module';

.protect-data .background {
	border-radius: 40px 0px 0px 40px;
	background: $turqoise;
	margin-left: 40%;
	font-size: 42px;
	font-style: normal;
	font-weight: 300;
	line-height: 48px;
	z-index: 100;
	position: relative;
}

.protect-data .container {
	margin-bottom: -128px;
	max-width: 100%;
	padding: 50px 24px 44px 60px;
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 42px;
	font-style: normal;
	font-weight: 300;
	line-height: 48px;
	z-index: 100;
	position: relative;
	box-sizing: border-box;
}

.protect-data a {
	display: inline-block;
	text-decoration: none;
	color: $dark-blue;
	padding-bottom: 15px;
	border-bottom: 1.5px solid $dark-blue;
}

.protect-data svg {
	margin-left: 5px;
}

.discover-card .container {
	margin-left: auto;
	margin: 0 auto 128px auto;
	padding: 259px 80px 155px 133px;
}

.discover-card .card-title {
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 72px;
	font-style: normal;
	font-weight: 300;
	line-height: 80px;
	margin-bottom: 56px;
}

.discover-card-list {
	margin-top: 132px;
	margin-left: auto;
	width: 78%;
	letter-spacing: 1.65px;
}

@media (width < 1441px) {
	.protect-data .background {
		margin-left: auto;
	}

	.protect-data .container {
		margin-bottom: -128px;
		padding: 50px 24px 44px 60px;
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 42px;
		font-style: normal;
		font-weight: 300;
		line-height: 48px;
		z-index: 100;
		position: relative;
	}

	.discover-card .container {
		border-radius: 0px 50px 50px 0px;
		background-color: $dark-blue;
		margin: 0 53px 128px 0;
		padding: 259px 80px 155px 133px;
		color: $off-white;
	}
}

@media (width < 1200px) {
	.protect-data .container {
		font-size: 32px;
		padding: 30px 24px 50px 45px;
		margin-bottom: -100px;
	}

	.discover-card .container {
		padding: 158px 59px 48px 59px;
	}

	.discover-card .card-title {
		margin-bottom: 23px;
		font-size: 52px;
		font-style: normal;
		font-weight: 300;
		line-height: 56px;
	}

	.discover-card-list {
		margin-top: 44px;
	}
}

@media (width < 700px) {
	.protect-data.wrapper {
		margin-left: 18px;
	}

	.protect-data .background {
		border-radius: 30px 0px 0px 30px;
		width: 100%;
		margin-left: auto !important;
	}

	.protect-data .container {
		padding: 14px 24px 30px 35px;
		margin-bottom: -48px;
	}

	.protect-data a {
		display: inline-block;
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		padding-bottom: 10px;
	}

	.protect-data svg {
		width: 27px !important;
		height: 35px !important;
		margin-left: 6px;
	}

	.discover-card .container {
		border-radius: 0px 40px 40px 0px;
		background-color: $dark-blue;
		margin: 0 24px 64px 0;
		padding: 88px 39px 48px 25px;
		color: $off-white;
	}

	.discover-card .card-title {
		margin: 0 0 23px 0;
		font-size: 32px;
		font-style: normal;
		font-weight: 300;
		line-height: 36px;
		width: 100%;
		letter-spacing: -0.16px;
	}

	.discover-card .card-subtitle {
		font-family: Degular;
		font-size: 19px;
		font-style: normal;
		line-height: 24px;
		width: 95%;
	}

	.discover-card-list {
		margin-top: 24px;
		margin-left: auto;
		width: 90%;
	}

	.discover-card-list svg {
		width: 23px !important;
	}
}
