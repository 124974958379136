@import 'src/styles/palette.module';

.ideal-block {
	.container {
		padding: 250px 133px 136px;
		display: flex;
		flex-direction: column;
	}

	.ideal-text {
		font-family: Mohol;
		font-feature-settings:
			'dlig' on,
			'ss03' on,
			'salt' on,
			'ss04' on;
		font-size: 48px;
		font-style: normal;
		font-weight: 300;
		line-height: 64px;
		width: 85%;
		max-width: 832px;
		& > span {
			border-bottom: 2px solid $dark-blue;
		}
	}

	.ideal-subtext {
		max-width: 456px;
		width: 100%;
		font-family: Degular;
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px;
		padding: 35px 0 65px;
		letter-spacing: 0.7px;
		& > a {
			color: $dark-blue;
			border-bottom: 1.5px solid $dark-blue;
		}
	}

	.ideal-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90%;
		gap: 5em;
		margin-left: auto;
		.ideal-content {
			& > a {
				& > .ideal-button {
					font-family: Degular;
					font-size: 20px;
					font-weight: 555;
					line-height: 32px;
					letter-spacing: 0.005em;
					text-align: center;
					padding: 20px 60px;
				}
			}
		}
	}

	.ideal-img {
		svg {
			width: 100%;
		}
	}

	@media (width < 1000px) {
		.container {
			padding: 200px 10% 10%;
			display: flex;
			flex-direction: column;
		}

		.ideal-text {
			font-size: 35px;
			line-height: 50px;
		}

		.ideal-subtext {
			width: 100%;
		}

		.ideal-container {
			width: 100%;
			margin-left: 0;
			gap: 1em;
		}
	}

	@media (width < 700px) {
		.container {
			padding: 90px 0 81px;
			display: flex;
			flex-direction: column;
		}
		.ideal-text {
			font-size: 26px;
			line-height: 32px;
			width: 100%;
		}

		.ideal-subtext {
			font-size: 19px;
			font-weight: 500;
			letter-spacing: 0.52px;
			line-height: 24px;
			width: 100%;
			margin-left: 40px;
			padding: 33px 0 42px;
		}

		.ideal-container {
			flex-direction: column;
			align-items: center;
			.ideal-content {
				& > a {
					& > .ideal-button {
						font-family: Degular;
						font-size: 18px;
						line-height: 24px;
						padding: 20px 50px;
						margin-left: 40px;
					}
				}
			}
		}

		.ideal-img {
			margin-top: 40px;
			width: 320px;
		}
		.container {
			flex-direction: column;
			padding: 90px 0 81px;
		}

		.ideal-text {
			padding: 0 24px 0 24px;
			width: auto;
			font-weight: 500;
			max-width: 288px;
		}

		.ideal-subtext {
			font-weight: 550;
			width: 90%;
		}

		.ideal-container {
			padding: 0 24px 0 24px;
			width: auto;
			gap: 0;
		}
		.ideal-img {
			margin-top: 20px;
		}
	}
}
