@import 'src/styles/palette.module';

.light-background .card-title {
	font-family: Mohol;
	font-size: 54px;
	font-style: normal;
	font-weight: 300;

	@media (width < 1200px) {
		font-size: 40px;
	}

	@media (width < 700px) {
		font-size: 32px;
	}
}
