@import 'src/styles/palette.module';

.consultation {
	padding: 152px 247px 0 194px;
}

.consultation .card-title {
	font-family: Degular;
	font-size: 20px;
	font-style: normal;
	font-weight: 555;
	line-height: 24px; /* 120% */
	letter-spacing: 1.2px;
	text-transform: uppercase;
	padding: 0;
}

.consultation .card-subtitle {
	padding: 25px 0 0 0;
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 48px;
	font-style: normal;
	line-height: 64px;
	letter-spacing: 0.1px;
	& > span {
		border-bottom: 2px solid $off-white;
	}
}

@media (width < 1300px) {
	.consultation {
		padding: 77px 104px 0 119px;
	}

	.consultation .card-subtitle {
		font-size: 35px;
	}
}

@media (width < 1000px) {
	.consultation {
		font-size: 40px;
		padding: 77px 44px 0 59px;
	}
}

@media (width < 700px) {
	.consultation {
		padding: 73px 24px 0 39px;
	}

	.consultation .card-title {
		padding: 0;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 1px;
	}

	.consultation .card-subtitle {
		padding: 13px 0 0 0;
		font-size: 26px;
		font-style: normal;
		line-height: 32px;
		letter-spacing: -0.13px;
		margin: 0;
	}
}
