@import 'src/styles/palette.module';

.how-it-works {
	.stepper-mobile-img-wrapper {
		display: none;
	}
	.stepper {
		box-sizing: border-box;
		padding: 174px 133px 168px;
		position: relative;
		.stepper-component {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			position: sticky;
			width: fit-content;
			left: calc(50% - 100px);
			top: 174px;
			z-index: 2;
			.stepper-line {
				width: 2px;
				height: calc(50vh - 62px - 140px);
				background: $dark-blue;
			}
			.stepper-number {
				color: $dark-blue;
				font-family: Degular;
				font-size: 30px;
				font-weight: 555;
				line-height: 32px;
				letter-spacing: 0.02em;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px 3px;
				border-radius: 100%;
				background: $off-white;
				border: 2px solid $dark-blue;
				width: 62px;
				height: 62px;
				box-sizing: border-box;
				transition: all 0.3s ease-in-out;
				&.active {
					background-color: $turqoise;
					border: 2px solid $turqoise;
				}
				&.passed {
					background-color: $dark-blue;
					color: $off-white;
					border: 2px solid $dark-blue;
				}
			}
		}
		.stepper-inner {
			margin-top: calc(-100vh + 342px);
			box-sizing: border-box;
			position: relative;
			.stepper-row {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				position: relative;
				&:last-child {
					.stepper-content {
						padding-bottom: 0;
					}
				}
				.stepper-img-wrapper {
					max-width: 416px;
					width: 100%;
					margin-right: 100px;
					position: sticky;
					top: 174px;
					left: 0;
					& > img {
						width: 100%;
						height: auto;
					}
				}
				.stepper-content {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					padding-bottom: 150px;
					width: 50%;
					.stepper-title {
						font-family: Mohol;
						font-size: 42px;
						font-weight: 300;
						line-height: 48px;
						text-align: left;
						color: $dark-blue;
						margin-bottom: 48px;
						max-width: 570px;
						width: 100%;
					}
					.stepper-text {
						margin-left: 80px;
						max-width: 490px;
						width: 100%;
					}
					.stepper-list-title {
						font-family: Degular;
						font-size: 20px;
						font-weight: 555;
						line-height: 24px;
						letter-spacing: 0.02em;
						text-align: left;
						text-transform: uppercase;
						color: $dark-blue;
						margin-bottom: 40px;
					}
					.stepper-list {
						margin-block-start: 0;
						margin-block-end: 0;
						padding-inline-start: 0;
					}
					.stepper-list-item {
						color: $dark-blue;
						font-family: Degular;
						font-size: 22px;
						font-weight: 650;
						line-height: 32px;
						letter-spacing: 0.0025em;
						text-align: left;
					}
					.stepper-list-item-title {
						margin: 40px 0 8px;
					}
					.stepper-list-item-text {
						font-size: 22px;
						font-weight: 500;
						line-height: 32px;
						letter-spacing: 0.0025em;
						margin-bottom: 10px;
					}
					.stepper-button {
						margin-top: 60px;
						color: $dark-blue;
						font-family: Degular;
						font-size: 20px;
						font-weight: 555;
						line-height: 24px;
						letter-spacing: 0.02em;
						text-align: left;
						text-decoration: underline;
						text-transform: uppercase;
						display: block;
						width: fit-content;
					}
				}
			}
		}
	}
	@media (width < 1200px) {
		.stepper {
			.stepper-component {
				left: calc(30% + 50px);
			}
			.stepper-inner {
				.stepper-row {
					.stepper-img-wrapper {
						width: 30%;
					}
					.stepper-content {
						width: 70%;
					}
				}
			}
		}
	}
	@media (width < 1000px) {
		.stepper {
			padding: 174px 10% 168px;
		}
	}
	@media (width < 900px) {
		.stepper-mobile-img-wrapper {
			display: block;
			max-width: 327px;
			margin: 174px auto 0;
			& > img {
				width: 100%;
			}
		}
		.stepper {
			padding: 50px 10% 168px;
			.stepper-component {
				left: 0;
			}
			.stepper-inner {
				.stepper-row {
					.stepper-img-wrapper {
						display: none;
					}
					.stepper-content {
						width: 100%;
						margin-left: 100px;
					}
				}
			}
		}
	}
	@media (width < 700px) {
		.stepper-mobile-img-wrapper {
			width: calc(100% - 24px * 2);
			margin: 64px auto 0;
		}
		.stepper {
			padding: 0;
			margin: 45px 24px 98px;
			.stepper-component {
				top: 150px;
				.stepper-number {
					width: 40px;
					height: 40px;
					font-size: 20px;
					line-height: 24px;
					padding: 0;
				}
			}
			.stepper-inner {
				margin-top: calc(-100vh + 410px);
				.stepper-row {
					.stepper-content {
						margin-left: 70px;
						padding-bottom: 113px;
						.stepper-title {
							font-size: 26px;
							line-height: 32px;
							margin-bottom: 25px;
						}
						.stepper-text {
							margin-left: 30px;
							width: initial;
							box-sizing: border-box;
						}
						.stepper-list-title {
							margin-bottom: 16px;
							font-size: 18px;
							line-height: 24px;
						}
						.stepper-list-item-title {
							font-size: 19px;
							line-height: 24px;
						}
						.stepper-list-item-text {
							font-size: 19px;
							line-height: 24px;
						}
						.stepper-button {
							font-size: 18px;
							line-height: 24px;
							margin-top: 30px;
							margin-left: auto;
							& > span {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
