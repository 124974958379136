@import 'src/styles/palette.module';

.contact {
	background: $dark-blue;
	margin-bottom: -65px;
	padding-bottom: 150px;
}

.contact .container {
	padding-top: 158px;
}

.contact .contact-container {
	margin: 0 133px;
}

.contact .main-text,
.contact .sub-text,
.contact .card-title {
	color: $off-white;
}

.contact .card-title {
	font-family: Degular;
	font-size: 20px;
	font-style: normal;
	font-weight: 555;
	line-height: 24px;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	margin: 0 133px 24px;
}

.contact .main-text {
	font-family: Mohol;
	font-feature-settings:
		'dlig' on,
		'ss03' on,
		'salt' on,
		'ss04' on;
	font-size: 42px;
	font-style: normal;
	font-weight: 300;
	line-height: 48px;
}

.contact .sub-text {
	font-family: Degular;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 145.455% */
	letter-spacing: 0.22px;
	margin-top: 51px;
	& > a {
		text-decoration: underline;
	}
}

.contact a {
	color: $off-white;
}

.contact {
	.contact-container {
		display: flex;
		justify-content: space-between;
		.contact-content {
			margin-right: 40px;
			max-width: 520px;
		}
		.contact-form {
			min-width: 400px;
			max-width: 570px;
			width: 100%;
			display: flex;
			flex-direction: column;
			.MuiTextField-root {
				width: 100%;
				margin-bottom: 40px;
			}
			& > .contact-row {
				.MuiTextField-root {
					width: calc(50% - 15px);
					&:first-child {
						margin-right: 15px;
					}
					&:last-child {
						margin-left: 15px;
					}
				}
			}
			.contact-submit {
				padding: 18px 72px;
				text-transform: uppercase;
				border-radius: 50px;
				border: 2px solid $turqoise;
				background: transparent;
				color: $turqoise;
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 32px;
				letter-spacing: 0.005em;
				text-align: center;
				align-self: center;
			}
		}
	}
}

@media (width < 1000px) {
	.contact .contact-container {
		margin: 0 10%;
		flex-direction: column;
		.contact-form {
			margin-top: 80px;
		}
	}
	.contact .card-title {
		margin: 55px 10% 24px;
	}
}

@media (width < 700px) {
	.contact {
		padding-bottom: 50px;
	}

	.contact .contact-container {
		margin: 0 24px;
	}

	.contact .contact-container .contact-content {
		margin-right: 0;
	}

	.contact .sub-text,
	.contact .card-title {
		color: $off-white;
		margin: 0 24px;
	}

	.contact .main-text {
		margin-top: 30px;
		font-size: 26px;
		font-style: normal;
		font-weight: 300;
		line-height: 32px;
	}

	.contact .sub-text {
		margin: 66px 0 0 clamp(10px, 25%, 100px);
		font-size: 19px;
		line-height: 24px;
	}
	.contact .contact-container .contact-form .MuiTextField-root {
		margin-bottom: 35px;
	}
	.contact .contact-container .contact-form {
		min-width: 100%;
		margin: 73px 0 70px;
		& > .contact-row {
			.MuiTextField-root {
				width: 100%;
				margin: 0 0 35px;
				&:first-child {
					margin-right: 0;
				}
				&:last-child {
					margin-left: 0;
				}
			}
		}
	}
	.contact .contact-container .contact-form .contact-submit {
		margin-top: 20px;
		padding: 18px 61px;
		font-size: 18px;
		line-height: 24px;
	}
}
