@import 'src/styles/palette.module';

.industry-overview {
	.power-block {
		margin: 97px 133px 129px;
		.power-title {
			color: $dark-blue;
			max-width: 718px;
			font-family: Mohol;
			font-size: 48px;
			font-weight: 300;
			line-height: 64px;
			text-align: left;
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
			& > span {
				border-bottom: 2px solid $dark-blue;
			}
		}
		.power-subtitle {
			color: $dark-blue;
			font-family: Degular;
			font-size: 22px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.0025em;
			text-align: left;
			max-width: 460px;
			margin: 214px 0 0 auto;
			& > b {
				font-weight: 650;
			}
		}
	}
	@media (width < 1000px) {
		.power-block {
			margin: 97px 10% 129px;
		}
	}
	@media (width < 900px) {
		.power-block {
			margin: 52px 24px 81px;
			.power-title {
				font-size: 26px;
				line-height: 32px;
				max-width: 400px;
			}
			.power-subtitle {
				max-width: 300px;
				margin: 75px 0 0 auto;
				font-size: 19px;
				line-height: 24px;
			}
		}
	}
}
