@import 'src/styles/palette.module';

.careers {
	padding-top: 192px;
	padding-bottom: 190px;
	background-color: $dark-blue;
	margin-bottom: -50px;

	& .wrapper {
		background-image: linear-gradient(to left, $turqoise 50%, transparent 50%);
	}
}

.careers-block {
	color: $off-white;
	display: flex;
	flex-direction: row;
	padding-left: 133px;
	max-width: 1440px;
	margin: 0 auto;
	box-sizing: border-box;
}

.careers .contact-button {
	background-color: $turqoise;
	color: $dark-blue !important;
}

.careers-block .card-title {
	margin-right: 64px;
	@media (width < 1300px) {
		margin-right: 30px;
	}
}

.careers-block .additional-title {
	margin-right: 64px;
	@media (width < 1300px) {
		margin-right: 30px;
	}
}

.careers-block .text {
	background-color: $turqoise;
	border-radius: 50px 0px 0px 50px;
	padding: 57px 113px 72px 84px;
	color: $dark-blue;
	max-width: 900px;
	width: 100%;
	box-sizing: border-box;
	@media (width > 1800px) {
		max-width: 60vw;
	}
}

.careers-block .main-text {
	font-family: Mohol;
	font-size: 48px;
	font-weight: 250;
	line-height: 64px;
	max-width: 900px;
	width: 100%;
	font-feature-settings:
		'dlig' on,
		'salt' on,
		'ss03' on,
		'ss04' on;
	& > a {
		color: $dark-blue;
		border-bottom: 1.5px solid $dark-blue;
	}
}

.careers-block .card-subtitle {
	margin: 41px 0 0 80px;
	max-width: 604px;
	width: 100%;
	box-sizing: border-box;
	@media (width < 1300px) {
		margin: 25px 0 0 30px;
		width: 100%;
	}
	& > a {
		color: $dark-blue;
		border-bottom: 1.5px solid $dark-blue;
	}
	& > .card-text {
		margin-top: 55px;
	}
}

.careers-block .card-subtitle a {
	color: $dark-blue;
}

.careers-block .card-subtitle p {
	margin: 0;
}

.linkedin-btn {
	color: $dark-blue;
	padding: 18px 75px;
	border-radius: 50px;
	border: 2px solid var(--Primary-Dark-blue, #0f1936);
	font-size: 20px;
	font-style: normal;
	font-weight: 455;
	line-height: 32px; /* 160% */
	letter-spacing: 0.1px;
	text-transform: uppercase;
	text-decoration: none;
	width: 256px;
	margin-top: 31px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex {
	.linkedin-btn {
		&:last-child {
			width: calc(100% - 256px);
		}
	}
}

@media (width > 1200px) {
	.flex {
		.linkedin-btn {
			&:last-child {
				width: calc(100% - 266px);
				margin-left: 10px;
			}
		}
	}
}

@media (width < 1200px) {
	.careers-block .sub-text {
		margin-left: 30px;
	}
	.careers .wrapper {
		background-image: none;
	}
	.careers-block .card-subtitle {
		.flex {
			flex-direction: column;
			.linkedin-btn {
				margin-top: 16px;
				font-size: 20px;
				line-height: 24px;
				padding: 20px;
				width: 100%;
				max-width: 256px;
				text-align: center;
			}
		}
	}
	.careers-block {
		padding: 10px 20 50px 24px;
		flex-direction: column;
	}

	.careers-block .card-title {
		margin: 0 0 37px 0;
	}

	.careers-block .impressum-title {
		margin: 0 0 37px 0;
	}
	.careers-block .text {
		align-self: flex-end;
	}
}

@media (width < 700px) {
	.careers {
		padding-top: 115px;
		padding-bottom: 50px;
	}
	.careers-block {
		padding: 10px 0 50px 24px;
		flex-direction: column;
	}

	.careers-block .card-title {
		margin: 0 0 37px 0;
	}

	.careers-block .impressum-title {
		margin: 0 0 37px 0;
	}	

	.careers-block .text {
		border-radius: 40px 0px 0px 40px;
		padding: 36px 20px 55px 39px;
	}

	.careers-block .card-subtitle {
		margin: 31px 0 0 32px;
		line-height: 24px;
		letter-spacing: 0.9px;
		font-size: 19px;
		width: calc(100% - 32px);
	}

	.careers-block .main-text {
		font-size: 26px;
		line-height: 32px;
	}

	.linkedin-btn {
		margin-top: 37px;
		padding: 15px 73px;
	}
}
