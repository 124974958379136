@import 'src/styles/palette.module';

.faq {
	& .button-dark.contact-button {
		color: $off-white;
	}
	& .menu-button.closed {
		rect {
			fill: $off-white;
			&:first-child {
				fill: $dark-blue;
			}
		}
	}
	& .changed-logo {
		svg.circle {
			& > path {
				fill: $off-white;
			}
		}
	}
	& > div > .title-card {
		& > .container {
			height: initial;
		}
	}
	.title-card {
		margin: 0 133px;
		& > .container {
			background: $off-white;
			padding-top: 146px;
		}
		.title-card-title.card-title {
			font-family: Mohol;
			font-size: 72px;
			font-weight: 300;
			line-height: 80px;
			letter-spacing: -0.01em;
			text-align: center;
			color: $dark-blue;
			margin: 0 auto 38px;
		}
		.title-card-subtitle {
			font-family: Degular;
			font-size: 26px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: 0.0025em;
			text-align: center;
			color: $dark-blue;
			max-width: 1106px;
			width: 100%;
			margin-bottom: 72px;
			& > a {
				text-decoration: underline;
				color: $dark-blue;
			}
		}
	}
	.dark-card {
		background: $dark-blue;
		border-radius: 50px;
		padding: 94px 133px 144px;
		.container {
			.faq-subtitle {
				padding-bottom: 26px;
				font-family: Degular;
				font-size: 20px;
				font-weight: 555;
				line-height: 24px;
				letter-spacing: 0.02em;
				text-align: left;
				color: $off-white;
				text-transform: uppercase;
			}
			& > .faq-category {
				margin-bottom: 47px;
				display: flex;
				flex-direction: column;
				&:last-child {
					margin-bottom: 0;
				}
				& > .faq-item {
					display: flex;
					flex: 1;
					justify-content: space-between;
					flex-direction: column;
					padding: 0 47px 0 47px;
					transition: all 0.1s linear;
					background: $turqoise;
					border-radius: 30px;
					margin-bottom: 24px;
					position: relative;
					&:last-child {
						margin-bottom: 0;
					}
					.faq-question {
						display: flex;
						flex: 1;
						& > div {
							display: flex;
							justify-content: space-between;
							font-family: Mohol;
							font-size: 32px;
							font-weight: 300;
							line-height: 36px;
							min-height: 128px;
							letter-spacing: 0.0025em;
							text-align: left;
							color: $dark-blue;
							position: relative;
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							font-feature-settings:
								'dlig' on,
								'salt' on,
								'ss03' on,
								'ss04' on;
							& > span {
								width: calc(100% - 48px - 10px);
								max-width: 980px;
							}
						}
					}
					.faq-answer {
						font-family: Degular;
						font-size: 22px;
						font-weight: 500;
						line-height: 32px;
						letter-spacing: 0.0025em;
						text-align: left;
						color: $dark-blue;
						display: none;
						max-width: 976px;
					}
					.faq-icon {
						cursor: pointer;
						width: 48px;
						height: 48px;
						transition: all 0.3s ease-in-out;
						& > svg {
							width: 100%;
							height: 100%;
						}
					}
					&.active {
						padding: 0px 47px 49px 47px;
						background: $off-white;
						max-height: 500px;
						.faq-answer {
							display: block;
						}
						.faq-icon {
							transform: rotate(45deg);
						}
					}
				}
			}
		}
	}
	.faq-contact-us {
		margin: 155px 133px 117px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.faq-contact-us-title {
			margin-bottom: 48px;
			color: $dark-blue;
			font-family: Mohol;
			font-size: 42px;
			font-weight: 300;
			line-height: 48px;
			text-align: center;
			max-width: 878px;
			font-feature-settings:
				'dlig' on,
				'salt' on,
				'ss03' on,
				'ss04' on;
		}
		.button-dark {
			padding: 20px 60px;
			text-transform: uppercase;
			font-family: Degular;
			font-size: 20px;
			font-weight: 555;
			line-height: 32px;
			letter-spacing: 0.005em;
			text-align: center;
		}
	}
	@media (width < 1000px) {
		.title-card {
			margin: 0 10%;
		}
		.dark-card {
			padding: 94px 10% 144px;
		}
		.faq-contact-us {
			margin: 155px 10% 117px;
		}
	}
	@media (width < 700px) {
		.title-card {
			margin: 0 24px;
			& > .container {
				padding-top: 115px;
			}
			.title-card-title.card-title {
				font-size: 40px;
				line-height: 40px;
				margin: 0 auto 20px;
			}
			.title-card-subtitle {
				font-size: 22px;
				line-height: 32px;
				margin-bottom: 70px;
			}
		}
		.dark-card {
			padding: 55px 24px 72px;
			border-radius: 40px;
			.container {
				.faq-subtitle {
					padding-bottom: 17px;
					font-size: 18px;
				}
				& > .faq-category {
					margin-bottom: 63px;
					& > .faq-item {
						padding: 24px 19px 16px 19px;
						border-radius: 20px;
						margin-bottom: 8px;
						&.active {
							padding: 24px 19px 40px 19px;
						}
						.faq-question {
							> div {
								min-height: unset;
								font-size: 22px;
								line-height: 24px;
								align-items: start;
								& > span {
									width: calc(100% - 32px - 10px);
								}
							}
						}
						.faq-answer {
							font-size: 19px;
							line-height: 24px;
							margin-top: 32px;
						}
						.faq-icon {
							cursor: pointer;
							width: 32px;
							height: 32px;
							top: 0;
							right: -55px;
						}
					}
				}
			}
		}
		.faq-contact-us {
			margin: 90px 24px 86px;
			.faq-contact-us-title {
				margin-bottom: 30px;
				font-size: 26px;
				line-height: 32px;
			}
			.button-dark {
				padding: 24px 65px;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}
}
