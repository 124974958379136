@import 'src/styles/palette.module';

.specific-use-case {
	overflow: hidden;
	.flex-center {
		align-items: center;
	}
	.flex-center.flex-column {
		margin: 0 133px;
	}
	.title-card-title {
		margin-left: 0;
		font-size: 72px;
		font-weight: 300;
		line-height: 80px;
		letter-spacing: -0.01em;
		text-align: center;
		max-width: 1018px;
		width: 100%;
	}
	.card-text {
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
		letter-spacing: 0.0025em;
		text-align: center;
		padding: 38px 0 250px;
		max-width: 650px;
		width: 100%;
	}
	.img-wrapper {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		& > img {
			width: 100%;
			max-width: 878px;
			margin: -138px auto 0;
		}
	}
	@media (width < 1200px) {
		.img-wrapper {
			& > img {
				width: calc(100% - 20%);
				max-width: 878px;
				margin: -250px 10% 0;
			}
		}
	}
	@media (width < 1000px) {
		.flex-center.flex-column {
			margin: 0 10%;
		}
	}
	@media (width < 900px) {
		.flex-center.flex-column {
			margin: 0 24px 250px;
		}
		.title-card-title {
			font-size: 32px;
			line-height: 36px;
			padding-right: 0;
		}
		.card-text {
			font-size: 19px;
			line-height: 24px;
			padding: 25px 0 20px;
		}
		.img-wrapper {
			& > img {
				width: auto;
				height: 224px;
				margin: -300px -60% 0 0;
			}
		}
		.title-card {
			.title-arrow {
				margin-bottom: 0;
			}
		}
	}
	@media (width < 600px) {
		.img-wrapper {
			& > img {
				width: auto;
				height: 224px;
				margin: -300px -45% 0 0;
			}
		}
	}
}
