@import 'src/styles/palette.module';

.how-it-works-card .container {
	padding: 122px 114px 192px 133px;
	box-sizing: border-box;
}

.how-it-works-card {
	margin-top: 300px;
	margin-right: 53px;
	margin-bottom: -80px;

	.card-title {
		font-size: 54px;
		font-style: normal;
		font-weight: 300;
		line-height: 64px;
	}

	.how-it-works-wrapper {
		.accordion-wrapper {
			border-top: 2px solid;
			border-color: $turqoise;
			margin-top: 35px;
			width: 570px;
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.flex {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 71px 0 36px;
		gap: 40px;
	}

	.how-it-works-wrapper {
		.accordion-wrapper .flex-row {
			padding: 14px 0 40px;
			cursor: pointer;
		}
	}

	// li::marker {
	// 	font-size: 12px;
	// 	top: 15px;
	// }

	li {
		list-style-type: none;
		display: flex;
		// align-items: center;
	}

	li::before {
		content: '';
		width: 3px;
		height: 3px;
		border-radius: 50%;
		background: $off-white;
		margin-right: 0.5rem;
		margin-top: 12px;
	}

	.how-it-works-wrapper {
		.accordion-wrapper.active {
			border-image: linear-gradient(to right, $off-white 60%, $turqoise 40%) 2;
		}
	}

	.accordion {
		width: 100%;
		text-align: left;
		border: none;
		outline: none;
		transition: 0.4s;
		background-color: $dark-blue;
		color: $turqoise;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 555;
		line-height: 24px; /* 120% */
		letter-spacing: 1px;
	}

	.accordion.active {
		color: $off-white;
	}

	.panel {
		overflow: hidden;
		margin: 0 0 0 30px;
		font-size: 20px;
		font-weight: 500;
		line-height: 24px; /* 130% */
		letter-spacing: 0.5%;
		transition: height 0.5s ease;
		& > ul {
			& > li {
				margin-bottom: 5px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.panel.active {
		transition: height 0.5s ease;
		& > p {
			margin-block-start: 0;
			margin-block-end: 0;
			font-size: 20px;
			font-weight: 529;
			line-height: 26px;
			letter-spacing: 0.0075em;
			text-align: left;
			max-width: 498px;
			margin-bottom: 15px;
		}
		& > ul {
			margin-block-start: 0;
			margin-block-end: 0;
			margin-top: 19px;
			padding-inline-start: 10px;
		}
	}

	.accordion-img {
		display: none;
	}

	.how-it-works-img-small {
		display: none;
	}

	@media (width < 1220px) {
		margin-bottom: -65px;
		margin-top: 250px;

		.accordion-img {
			display: block;
		}

		.accordion-img {
			display: none;
		}

		.flex {
			flex-direction: column;
			gap: 0;
		}

		.how-it-works-img-wrapper {
			display: none;
		}

		.how-it-works-wrapper {
			.accordion-wrapper {
				width: 100%;
			}
		}

		.how-it-works-img-small {
			display: block;
			padding: 40px 0;
		}
	}

	@media (width < 800px) {
		margin-bottom: -50px;
		margin-top: 150px;
		margin-right: 24px;

		.container {
			padding: 83px 59px 156px 44px;
		}

		.card-title {
			font-size: 45px;
			line-height: 48px;
		}

		.how-it-works-img-small {
			max-width: 100%;
		}
		.flex {
			padding: 51px 0 36px;
		}
	}

	@media (width < 500px) {
		.container {
			padding: 54px 39px 80px 24px;
			border-radius: 0 40px 40px 0;
		}

		.card-title {
			font-size: 32px;
			line-height: 36px;
		}

		.how-it-works-wrapper {
			.accordion-wrapper.active {
				border-image: none;
				border-top: 2px solid $off-white;
			}
		}

		.accordion {
			font-size: 18px;
		}

		.panel {
			font-size: 18px;
		}

		li::marker {
			font-size: 10px;
			margin-bottom: -20px;
		}

		.accordion {
			padding-left: 0;
		}
	}
}
