@import 'src/styles/palette.module';

.additional-page {
    padding-top: 192px;
    padding-bottom: 190px;
    background-color: $dark-blue;
    margin-bottom: -50px;
    
    a {
        color: $dark-blue;
        text-decoration: underline !important;
    }

	& .wrapper {
		background-image: linear-gradient(to left, $turqoise 40%, transparent 40%);
        @media (width < 1200px) {
            background-image: none;
        }
    }

    @media (width < 700px) {
        padding-top: 115px;
        padding-bottom: 50px;
    }
}

@mixin margin-right-adjustment {
    margin-right: 64px;
    @media (width < 1300px) {
        margin-right: 30px;
    }
}

.additional-block {
    padding: 0 0 0 130px;
    color: $off-white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 1440px;
	margin: 0 auto;

    @media (width > 1440px) {
        padding-top: 0;
    }

    @media (width < 1200px) {
        padding: 10px 0 50px 24px;
        flex-direction: column;
    }

    @media (width < 700px) {
        padding: 10px 0 50px 24px;
        flex-direction: column;
    }
}

.additional-block .card-title,
.additional-block .additional-title {
    @include margin-right-adjustment;

    @media (width < 1200px) {
        margin: 0 0 37px 0;
    }

    @media (width < 700px) {
        margin: 0 0 37px 0;
    }
}

.additional .contact-button {
    background-color: $turqoise;
    color: $dark-blue !important;
}

.additional-block .text {
    background-color: $turqoise;
    border-radius: 50px 0px 0px 50px;
    padding: 57px 113px 72px 84px;
    color: $dark-blue;
    max-width: 900px;
    width: 100%;
    box-sizing: border-box;

    @media (width > 1800px) {
        padding: 87px calc((100vw - 1440px) / 2 + 133px) 72px 84px;
        max-width: 60vw;
    }

    @media (width < 700px) {
        border-radius: 40px 0px 0px 40px;
        padding: 36px 20px 55px 39px;
    }

    @media (width < 1200px) {
        align-self: flex-end;
    }
}

.additional-block .main-text {
    font-family: Mohol;
    font-size: 22px;
    font-weight: 250;
    line-height: 64px;
    max-width: 900px;
    width: 100%;
    font-feature-settings: 'dlig' on, 'salt' on, 'ss03' on, 'ss04' on;

    & > a {
        color: $dark-blue;
        border-bottom: 1.5px solid $dark-blue;
    }

    @media (width < 700px) {
        margin-top: 31px;
        font-size: 26px;
        line-height: 32px;
    }
}

.additional-block .card-subtitle {
    margin: 0px;
    max-width: 701px;
    width: 100%;
    box-sizing: border-box;

    @media (width < 1300px) {
        margin: 0px;
        width: 100%;
    }

    @media (width < 700px) {
        margin: 31px 0 0 32px;
        line-height: 24px;
        letter-spacing: 0.9px;
        font-size: 19px;
        width: calc(100% - 32px);
    }

    & > a {
        color: $dark-blue;
        text-decoration: none;
    }

    & > .card-text {
        margin-top: 55px;
    }

    p {
        margin: 0;
    }
}

.linkedin-btn {
    color: $dark-blue;
    padding: 18px 75px;
    border-radius: 50px;
    border: 2px solid var(--Primary-Dark-blue, #0f1936);
    font-size: 20px;
    font-style: normal;
    font-weight: 455;
    line-height: 32px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    text-decoration: none;
    width: 256px;
    margin-top: 31px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (width < 700px) {
        margin-top: 37px;
        padding: 15px 73px;
    }
}

.flex {
    .linkedin-btn {
        &:last-child {
            width: calc(100% - 256px);

            @media (width > 1200px) {
                width: calc(100% - 266px);
                margin-left: 10px;
            }
        }
    }
}

@media (width < 1200px) {
    .additional-block .sub-text {
        margin-left: 30px;
    }

    .additional-block .card-subtitle {
        .flex {
            flex-direction: column;

            .linkedin-btn {
                margin-top: 16px;
                font-size: 20px;
                line-height: 24px;
                padding: 20px;
                width: 100%;
                max-width: 256px;
                text-align: center;
            }
        }
    }
}
